import { Connection } from '@solana/web3.js';
import React, { useMemo, useState, createContext, useContext, useCallback } from 'react';

export const RPC_ENDPOINT = 'https://cindie-7oi4z0-fast-mainnet.helius-rpc.com';
export const WS_ENDPOINT = "wss://mainnet.helius-rpc.com/?api-key=26dd16db-a14e-4393-a04f-fe0fc72db377";

export const ConnectionContext = createContext({
    connection: new Connection(RPC_ENDPOINT, { ...new Connection(RPC_ENDPOINT).config, wsEndpoint: WS_ENDPOINT }),
    refreshConnection: () => { },
});

export function ConnectionProvider({ children, endpoint, config = { commitment: 'confirmed' } }) {
    const [connection, setConnection] = useState(new Connection(RPC_ENDPOINT, { ...config, wsEndpoint: WS_ENDPOINT }));

    const refreshConnection = useCallback(() => {
        setConnection(new Connection(RPC_ENDPOINT, { ...config, wsEndpoint: WS_ENDPOINT }));
    }, [config]);

    const contextValue = useMemo(
        () => ({ connection, refreshConnection }),
        [connection, refreshConnection]
    );

    return (
        <ConnectionContext.Provider value={contextValue}>
            {children}
        </ConnectionContext.Provider>
    );
}

export function useConnection() {
    return useContext(ConnectionContext);
}

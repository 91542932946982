import React from "react";
import { TouchableOpacity, StyleSheet, Text, Image } from "react-native";
import LinearGradient from "react-native-web-linear-gradient";
import { useNavigation } from "@react-navigation/native";

// Import WebP images
const Icons = {
    receive: require("../assets/orange_icons/Receive.webp"),
    send: require("../assets/orange_icons/Send.webp"),
    stake: require("../assets/orange_icons/stake_active.webp"),
    fund: require("../assets/orange_icons/Fund.webp"),
    unstake: require("../assets/orange_icons/Unstake.webp"),
    merge: require("../assets/orange_icons/Merge_2.webp"),
    wallet: require("../assets/orange_icons/Wallet.webp"),
};

const styles = StyleSheet.create({
    container: {
        maxWidth: 85,
        height: 75,
        borderWidth: 1,
        borderColor: "#4a4a4a",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 10,
    },
    content: {
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    label: {
        color: "white",
        fontFamily: "PP Neue Machina Plain",
        fontSize: 12,
        marginTop: 5,
    },
    icon: {
        width: 20,
        height: 20,
        resizeMode: "contain",  // Ensures images scale properly
    },
});

export default function WalletActionButton({ title, icon, route }) {
    const navigation = useNavigation();
    const imageSource = Icons[icon] || Icons.wallet;

    return (
        <TouchableOpacity onPress={route ? () => navigation.navigate(route) : null} style={styles.container}>
            <LinearGradient colors={["#4e4e4e4d", "#1d1d1d4d"]} start={{ x: 0.5, y: 0 }} end={{ x: 0.5, y: 1 }} style={styles.content}>
                <Image source={imageSource} style={styles.icon} />
                <Text style={styles.label}>{title}</Text>
            </LinearGradient>
        </TouchableOpacity>
    );
}

import React, { useEffect, useState } from "react";
import { Platform, StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Icon from '@react-native-vector-icons/fontawesome6';
import LinearGradient from "react-native-web-linear-gradient";
import { useSolanaWallets } from '@privy-io/react-auth/solana';
import LoadingSpinner from "../components/LoadingSpinner";
import { usePrivyWalletContext } from "../components/providers/PrivyWalletProvider";

const CreateWalletScreen = () => {
    const [loading, setLoading] = useState(false);
    const { wallets } = useSolanaWallets();
    const navigation = useNavigation();
    const { createWallet } = usePrivyWalletContext();

    useEffect(() => {
        if (wallets.length > 0 && wallets[0].address) {
            setLoading(false);
            navigation.navigate("Stake");
        }
    }, [wallets, navigation]);

    return (
        <View style={styles.container}>
            <View>
                <Text style={[styles.loginMethodText, styles.header]}>Now we create your wallet!</Text>
            </View>
            {!loading ? <LinearGradient
                colors={['#4e4e4e4d', '#1d1d1d4d']}
                start={{ x: 0.5, y: 0 }}
                end={{ x: 0.5, y: 1 }}
                style={styles.gradient}
            >
                <TouchableOpacity
                    style={styles.loginMethod}
                    onPress={createWallet}
                >

                    <View style={styles.imageContainer}>
                        <Icon
                            name="wallet"
                            style={styles.icon}
                            iconStyle="solid"
                            size={30}
                        />
                    </View>
                    <View style={styles.infoContainer}>
                        <Text style={styles.loginMethodText}>Create Wallet</Text>
                    </View>
                </TouchableOpacity>
            </LinearGradient> : (
                <View style={styles.gradient}>
                    <LoadingSpinner />
                </View>
            )}

        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'black',
        paddingTop: 50,
        height: '100%',
        alignItems: 'center',
    },
    header: {
        marginBottom: 20,
    },
    gradient: {
        borderRadius: 25,
        marginBottom: 10,
    },
    loginMethod: {
        borderWidth: 1,
        borderColor: '#4a4a4a',
        maxWidth: 400,
        borderRadius: 25,
        padding: 16,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        ...(Platform.OS === 'web' && { flexGrow: 1, flex: 1 }),
        alignItems: 'center',
    },
    loginMethodText: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
        overflow: 'hidden',
        textAlign: 'center',
        marginBottom: 2,
    },
    loginMethodSubtext: {
        color: '#FF8400',
        fontFamily: 'PP Neue Machina Plain',
        overflow: 'hidden',
        textAlign: 'center',
        marginBottom: 2,
        fontSize: 12,
    },
    imageContainer: {
        paddingRight: 30,
    },
    infoContainer: {
        paddingRight: 30,
        width: 300,
    },
    icon: {
        borderRadius: 50,
        justifyContent: 'center',
        alignItems: 'center',
        color: '#FF8400',
    },
});

export default CreateWalletScreen;
import React, { useState } from 'react';
import { Text } from 'react-native';
import { TouchableOpacity, StyleSheet, View, Animated } from 'react-native';
import FontAwesome from "@react-native-vector-icons/fontawesome6";
import { useNavigation } from '@react-navigation/native';
import { usePrivyWalletContext } from './providers/PrivyWalletProvider';

const ConnectWalletButton = () => {
    const { selectedAccount, logout } = usePrivyWalletContext();
    const [showCopied, setShowCopied] = useState(false);
    const fadeAnim = useState(new Animated.Value(0))[0];
    const navigation = useNavigation();

    const formatAccount = (publicKey) => {
        if (!publicKey) return "";
        return `${publicKey.slice(0, 5)}...${publicKey.slice(-5)}`;
    };

    const handleOnPress = async () => {
        if (selectedAccount) {
            try {
                await logout();
            } catch (e) {
                console.log(e);
            }

        } else {
            navigation.navigate("LoginMethods");
        }
    };

    const copyAddress = () => {
        navigator.clipboard.writeText(selectedAccount)
            .then(() => {
                setShowCopied(true);
                Animated.timing(fadeAnim, {
                    toValue: 1,
                    duration: 200,
                    useNativeDriver: true,
                }).start(() => {
                    setTimeout(() => {
                        Animated.timing(fadeAnim, {
                            toValue: 0,
                            duration: 200,
                            useNativeDriver: true,
                        }).start(() => setShowCopied(false));
                    }, 1000);
                });
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };


    return (
        <View style={styles.container}>
            <TouchableOpacity onPress={() => handleOnPress()} style={styles.connectButton}>
                <Text style={styles.connectButtonText}>{formatAccount(selectedAccount) || "Connect Wallet"}</Text>
            </TouchableOpacity>
            {selectedAccount && (
                <View style={styles.container}>
                    <TouchableOpacity onPress={copyAddress}>
                        <FontAwesome style={styles.exportIcon} iconStyle="solid" name="copy" size={30} color='#FF8400' />
                    </TouchableOpacity>
                </View>
            )}

            {showCopied && (
                <Animated.View style={[styles.tooltip, { opacity: fadeAnim }]}>
                    <Text style={styles.tooltipText}>Copied</Text>
                </Animated.View>
            )}
        </View>
    );
};

export default ConnectWalletButton;

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
    },
    connectButton: {
        borderWidth: 1,
        borderColor: '#FF8400',
        backgroundColor: '#FF8400',
        color: '#000000',
        paddingVertical: 8,
        paddingHorizontal: 16,
        fontFamily: 'PP Neue Machina Plain',
        borderRadius: 8,
        textAlign: 'center',
    },
    connectButtonText: {
        fontFamily: 'PP Neue Machina Plain',
    },
    exportIcon: {
        marginLeft: 5,
    },
    tooltip: {
        position: "absolute",
        top: 40,
        left: "75%",
        transform: [{ translateX: -30 }],
        backgroundColor: "black",
        paddingVertical: 5,
        paddingHorizontal: 10,
        borderRadius: 5,
    },
    tooltipText: {
        color: "white",
        fontFamily: 'PP Neue Machina Plain',
        fontSize: 12,
    },
});
import React, { useEffect, useState } from "react";
import { StyleSheet, Text, ScrollView, View, Platform, Switch, TouchableOpacity } from "react-native";
import LinearGradient from "react-native-web-linear-gradient";
import { usePrivy } from '@privy-io/react-auth';
import FontAwesome from "@react-native-vector-icons/fontawesome6";
import { usePrivyWalletContext } from '../components/providers/PrivyWalletProvider';

export default function SettingsScreen() {
    const [passkeyEnabled, setPasskeyEnabled] = useState(false);
    const { user } = usePrivy();
    const { exportWallet, linkWithPasskey } = usePrivyWalletContext();

    useEffect(() => {
        if (!user) return;
        for (let i = 0; i < user.linkedAccounts.length; i++) {
            console.log(user);
            if (user.linkedAccounts[i].type === "passkey") {
                setPasskeyEnabled(true);
                break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);


    return (
        <ScrollView
            style={styles.scrollViewContainer}
            contentContainerStyle={styles.scrollViewContainerContent}
        >
            <LinearGradient
                colors={['#4e4e4e4d', '#1d1d1d4d']}
                start={{ x: 0.5, y: 0 }}
                end={{ x: 0.5, y: 1 }}
                style={styles.container}
            >
                <View style={[styles.setting, styles.settingsPaddingTop]}>
                    <Text style={[styles.settingsText]}>Passkey enrolled</Text>
                    <Switch
                        // trackColor={{ false: '#767577', true: '#D3D3D3' }}
                        // thumbColor={passkeyEnabled ? '#FF8400' : '#f4f3f4'}
                        trackColor={'#f4f3f4'}
                        thumbColor={'#FF8400'}
                        activeTrackColor={'#FF8400'}
                        activeThumbColor={'#f4f3f4'}
                        ios_backgroundColor="#D3D3D3"
                        disabled={passkeyEnabled}
                        onValueChange={() => linkWithPasskey()}
                        value={passkeyEnabled}
                    />
                </View>
                <View style={[styles.setting, styles.settingsPaddingTop]}>
                    <Text style={[styles.settingsText]}>Export Private Key</Text>
                    <TouchableOpacity onPress={exportWallet}>
                        <FontAwesome style={styles.exportIcon} iconStyle="solid" name="download" size={30} color='#FF8400' />
                    </TouchableOpacity>
                </View>
            </LinearGradient>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    scrollViewContainer: {
        backgroundColor: 'black',
        flex: 1,
        ...(Platform.OS === "ios" && { paddingTop: 30 }),
    },
    scrollViewContainerContent: {
        alignItems: 'center',
        height: '100%',
        marginTop: 25,
        marginLeft: 25,
        marginRight: 25,
    },
    container: {
        width: '100%',
        borderRadius: 16,
        padding: 24,
        minWidth: 300,
    },
    setting: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    settingBorder: {
        borderBottomColor: '#D3D3D3',
        borderBottomWidth: 1,
    },
    settingPaddingBottom: {
        paddingBottom: 15,
    },
    settingsPaddingTop: {
        paddingTop: 25,
    },
    settingsText: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
        fontSize: 12,
    },
});
import React, { useState, useCallback, useEffect } from 'react';
import { Platform, TextInput } from 'react-native';
import { Text, View, ScrollView, StyleSheet, TouchableOpacity, Linking, Image } from "react-native";
import LinearGradient from "react-native-web-linear-gradient";
import { useFocusEffect } from '@react-navigation/native';
import BigNumber from "bignumber.js";
import { usePrivyWalletContext } from '../components/providers/PrivyWalletProvider';
import { ComputeBudgetProgram, LAMPORTS_PER_SOL, PublicKey, SystemProgram, VersionedTransaction, TransactionMessage } from '@solana/web3.js';
import { getAccount, getAssociatedTokenAddress, createTransferInstruction, createAssociatedTokenAccountInstruction, TokenAccountNotFoundError } from "@solana/spl-token";
import { useConnection } from '../components/providers/ConnectionProvider';
import LoadingSpinner from '../components/LoadingSpinner';
import { Dropdown } from 'react-native-element-dropdown';

const SendScreen = () => {
    const { selectedAccount, signAndSendTransaction, balance, rpcCall, tokenAccountsAndBalance, balancesLoaded } = usePrivyWalletContext();
    const [amount, setAmount] = useState(0);
    const [addressValid, setAddressValid] = useState(false);
    const [addressVerificationText, setAddressVerificationText] = useState("");
    const [recipient, setRecipient] = useState("");
    const [result, setResult] = useState("");
    const [txHash, setTxHash] = useState("");
    const [tokens, setTokens] = useState([]);
    const [loading, setLoading] = useState(false);
    const [asset, setAsset] = useState({
        mint: "So11111111111111111111111111111111111111112",
        symbol: "SOL",
        name: "Solana",
        logoURI: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png",
        decimals: 9,
        amount: 0, // filler which is isn't used
    });
    const { connection } = useConnection();

    useFocusEffect(
        useCallback(() => {
            setAmount(0);
            setAddressValid(false);
            setAddressVerificationText("");
            setRecipient("");
            setResult("");
            setTxHash("");
            setLoading(false);
        }, [])
    );

    useEffect(() => {
        if (balancesLoaded) {
            setTokens(tokenAccountsAndBalance.map(account => ({
                mint: account.mint,
                symbol: account.symbol,
                logoURI: account.logoURI,
                name: account.name,
                decimals: account.decimals,
                amount: account.amount,
            })));
            setAsset(tokenAccountsAndBalance.filter(asset => asset.mint === "So11111111111111111111111111111111111111112")[0]);
        }
    }, [tokenAccountsAndBalance, balancesLoaded]);

    const handleAmount = (text) => {
        // Replace all characters except digits and a single decimal point
        const amount = text.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
        setAmount(amount);
    };

    const handleTxHashPress = () => {
        Linking.openURL(`https://solscan.io/tx/${txHash}`);
    };

    const handleSetRecipient = (address) => {
        setRecipient(address);
        try {
            new PublicKey(address);
            setAddressValid(true);
            setAddressVerificationText(address);
        } catch (e) {
            setAddressValid(false);
            setAddressVerificationText("Enter a valid Solana address.");
        }
    };

    const dummyTx = async () => {
        const {
            value: { blockhash }
        } = await rpcCall(() => connection.getLatestBlockhashAndContext());
        const priorityFeeIX = ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 150000 });
        const computeUnitIX = ComputeBudgetProgram.setComputeUnitLimit({ units: 500 });
        const sendIX = SystemProgram.transfer({
            fromPubkey: new PublicKey(selectedAccount),
            toPubkey: new PublicKey(selectedAccount),
            lamports: 0,
        });
        const message = new TransactionMessage({
            payerKey: new PublicKey(selectedAccount),
            recentBlockhash: blockhash,
            instructions: [priorityFeeIX, computeUnitIX, sendIX]
        }).compileToV0Message();
        return message;
    };

    const handleMaxPress = useCallback(async () => {
        try {
            if (asset.mint !== "So11111111111111111111111111111111111111112") {
                setAmount(asset.amount / Math.pow(10, asset.decimals));
            } else {
                const rent = await rpcCall(() => connection.getMinimumBalanceForRentExemption(0));
                const dummyTransaction = await dummyTx();
                const baseFee = await rpcCall(() => connection.getFeeForMessage(dummyTransaction));

                const sendAmount = new BigNumber(balance)
                    .minus(new BigNumber(baseFee.value).plus(75000).plus(rent));

                if (sendAmount.toNumber() > 0) {
                    setAmount(sendAmount.dividedBy(LAMPORTS_PER_SOL).toNumber());
                }
            }
        } catch (e) {
            console.log(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asset]);

    const handleSend = useCallback(async () => {
        try {
            setLoading(true);
            const instructions = [];
            instructions.push(ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 150000 }));
            if (asset.mint === "So11111111111111111111111111111111111111112") {
                instructions.push(ComputeBudgetProgram.setComputeUnitLimit({ units: 500 }));
                instructions.push(SystemProgram.transfer({
                    fromPubkey: new PublicKey(selectedAccount),
                    toPubkey: new PublicKey(recipient),
                    lamports: new BigNumber(amount).multipliedBy(LAMPORTS_PER_SOL).toNumber(),
                }));
            } else {
                instructions.push(ComputeBudgetProgram.setComputeUnitLimit({ units: 50000 }));
                const fromTokenAccount = await getAssociatedTokenAddress(
                    new PublicKey(asset.mint),
                    new PublicKey(selectedAccount),
                );
                const toTokenAccount = await getAssociatedTokenAddress(
                    new PublicKey(asset.mint),
                    new PublicKey(recipient),
                );
                try {
                    await getAccount(connection, toTokenAccount);
                } catch (e) {
                    if (e instanceof TokenAccountNotFoundError) {
                        instructions.push(createAssociatedTokenAccountInstruction(
                            new PublicKey(selectedAccount),
                            toTokenAccount,
                            new PublicKey(recipient),
                            new PublicKey(asset.mint),
                        ));
                    } else {
                        console.log(e);
                    }
                }
                instructions.push(
                    createTransferInstruction(fromTokenAccount, toTokenAccount, new PublicKey(selectedAccount), amount * Math.pow(10, asset.decimals)),
                );
            }

            const {
                value: { blockhash }
            } = await rpcCall(() => connection.getLatestBlockhashAndContext());
            const message = new TransactionMessage({
                payerKey: new PublicKey(selectedAccount),
                recentBlockhash: blockhash,
                instructions,
            }).compileToV0Message();
            const transaction = new VersionedTransaction(message);
            const { sentTxHash, confirmation, simulationError } = await signAndSendTransaction(transaction, new PublicKey(selectedAccount));
            if (simulationError) {
                setResult("Transaction failed to simulate. Make sure you have enough in your balance to proceed.");
            } else if (!confirmation.value.err) {
                setResult("Transaction successful!");
                setTxHash(sentTxHash);
            } else {
                setResult("Transaction failed.");
            }
        } catch (e) {
            console.log(e);
            setResult("An unknown error occurred. Please check your wallet history.");
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipient, asset, amount]);

    return (
        <ScrollView
            style={styles.scrollViewContainer}
            contentContainerStyle={styles.scrollViewContainerContent}
        >
            <LinearGradient
                colors={['#4e4e4e4d', '#1d1d1d4d']}
                start={{ x: 0.5, y: 0 }}
                end={{ x: 0.5, y: 1 }}
                style={styles.container}
            >
                <Text style={[styles.headerText, styles.header]}>Recipient</Text>
                <View style={styles.recipientInputContainer}>
                    <TextInput
                        style={styles.recipientInput}
                        placeholder=""
                        value={recipient}
                        onChangeText={handleSetRecipient}
                    />
                    <Text style={[styles.resultText, addressValid ? styles.valid : styles.invalid]}>{addressVerificationText}</Text>
                </View>
                <Text style={[styles.headerText, styles.header]}>Amount</Text>
                <View style={styles.inputContainer}>
                    <TextInput
                        style={styles.input}
                        placeholder="0"
                        value={String(amount)}
                        onChangeText={handleAmount}
                        keyboardType="numeric"
                    />
                    <View style={styles.inputControls}>
                        <TouchableOpacity style={styles.maxButton} onPress={handleMaxPress}>
                            <Text style={[styles.maxButtonText]}>Max</Text>
                        </TouchableOpacity>
                        <Dropdown
                            data={tokens}
                            labelField="symbol"
                            valueField="symbol"
                            value={"SOL"}
                            selectedTextStyle={styles.selectedText}
                            selectedTextProps={{
                                numberOfLines: 1,
                                adjustsFontSizeToFit: true,
                                allowFontScaling: true,
                            }}
                            itemContainerStyle={styles.listContainer}
                            dropdownPosition="bottom"
                            style={styles.dropdownContainer}
                            containerStyle={styles.dropdownPosition}
                            maxHeight={200}
                            renderItem={(item, isSelected) => {
                                return (
                                    <View style={[styles.listItem]}>
                                        {item.logoURI ? (
                                            <Image
                                                source={{
                                                    uri: item.logoURI,
                                                    headers: { "User-Agent": "Orangefin-Mobile-App" },
                                                }}
                                                onError={() => {
                                                    const newTokens = [...tokens];
                                                    for (let i = 0; i < newTokens.length; i++) {
                                                        if (newTokens[i].logoURI === item.logoURI) {
                                                            newTokens[i] = {
                                                                ...newTokens[i],
                                                                logoURI: "",
                                                            }
                                                        }
                                                    }
                                                    setTokens(newTokens);
                                                }}
                                                style={styles.listIcon}
                                            />
                                        ) : (
                                            <View style={[styles.logo, styles.fillerLogo]}>
                                                <Text
                                                    numberOfLines={1}
                                                    adjustsFontSizeToFit
                                                    minimumFontScale={0.5}
                                                    allowFontScaling={true}
                                                >
                                                    {item.symbol}
                                                </Text>
                                            </View>
                                        )}
                                        <Text style={[styles.listText, isSelected ? styles.selectedListText : null]}>{item.symbol}</Text>
                                    </View>
                                );
                            }}
                            onChange={setAsset}
                        />
                    </View>
                </View>
            </LinearGradient>
            {!loading ? (
                <TouchableOpacity
                    style={[styles.buyButton, amount <= 0 || addressValid === false ? styles.disabledButton : null]}
                    disabled={amount <= 0 || !addressValid || loading}
                    onPress={handleSend}
                >
                    <Text style={styles.buttonText}>SEND</Text>
                </TouchableOpacity>
            ) : <LoadingSpinner />}
            {result !== "" ? (
                <LinearGradient
                    colors={['#4e4e4e4d', '#1d1d1d4d']}
                    start={{ x: 0.5, y: 0 }}
                    end={{ x: 0.5, y: 1 }}
                    style={styles.gradient}
                >
                    <TouchableOpacity disabled={txHash === ""} style={styles.resultContainer} onPress={handleTxHashPress}>
                        <View style={styles.resultWrapper}>
                            <Text style={styles.apyLabel}>{result}</Text>
                            {txHash !== "" ? (
                                <Text style={styles.apyLabel}>Click here to view transaction.</Text>
                            ) : null}
                        </View>
                    </TouchableOpacity>
                </LinearGradient>
            ) : null}
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    scrollViewContainer: {
        backgroundColor: 'black',
        flex: 1,
        ...(Platform.OS === "ios" && { paddingTop: 30 }),
    },
    scrollViewContainerContent: {
        alignItems: 'center',
        height: '100%',
        marginTop: 25,
        marginLeft: 25,
        marginRight: 25,
    },
    gradient: {
        marginTop: 15,
        borderRadius: 25,
        marginBottom: 10,
        width: 600,
    },
    container: {
        width: 600,
        borderRadius: 16,
        padding: 24,
        minWidth: 300,
    },
    buttonContainer: {
        marginTop: 15
    },
    disabledButton: {
        opacity: 0.5,
    },
    invalid: {
        color: 'red',
    },
    valid: {
        color: 'green',
    },
    logo: {
        width: 24,
        height: 24,
        marginRight: 10,
        borderRadius: 50,
        justifyContent: 'center',
        alignItems: 'center',
    },
    fillerLogo: {
        backgroundColor: "#D3D3D3",
        borderRadius: 50,
    },
    apyLabel: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
        textAlign: 'center',
    },
    resultContainer: {
        borderRadius: 16,
        padding: 16,
        width: '100%',
        alignItems: 'center',
    },
    resultWrapper: {
        padding: Platform.OS === "web" ? 16 : 5,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
    },
    recipientInput: {
        backgroundColor: 'rgba(0,0,0,0.5)',
        borderWidth: 1,
        borderColor: '#717171',
        borderRadius: 25,
        fontFamily: 'PP Neue Machina Plain',
        color: 'white',
        fontSize: 18,
        paddingVertical: 12,
        paddingHorizontal: 16,
    },
    input: {
        flex: 1,
        color: 'white',
        fontSize: 16,
        fontFamily: 'PP Neue Machina Plain',
        paddingVertical: 12,
        paddingHorizontal: 15,
        borderRadius: 25,
    },
    maxButton: {
        backgroundColor: '#d9d9d9',
        paddingVertical: 4,
        paddingHorizontal: 8,
        borderRadius: 4,
        marginRight: 10,
    },
    inputSuffix: {
        marginTop: 5,
        marginRight: 5,
        marginLeft: 10,
        color: '#717171',
        fontFamily: 'PP Neue Machina Plain',
    },
    inputControls: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: 140,
    },
    maxButtonText: {
        color: 'black',
        fontFamily: 'PP Neue Machina Plain',
    },
    recipientInputContainer: {
        position: 'relative',
        marginBottom: 16,
        width: '100%',
    },
    inputContainer: {
        position: 'relative',
        marginBottom: 16,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#000',
        borderRadius: 25,
        borderWidth: 1,
        borderColor: '#717171',
    },
    headerText: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
        overflow: 'hidden',
        textAlign: 'left',
        marginBottom: 2,
    },
    resultText: {
        fontFamily: 'PP Neue Machina Plain',
        fontSize: 10,
        textAlign: 'left',
        marginBottom: 2,
        marginTop: 3,
    },
    header: {
        marginBottom: 15,
    },
    buyButton: {
        backgroundColor: '#d9d9d9',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 35,
        alignItems: 'center',
        paddingVertical: 12,
        borderRadius: 25,
        width: 600,
    },
    buttonText: {
        fontFamily: 'PP Neue Machina Plain',
        color: 'black',
    },
    selectedListText: {
        color: 'black',
    },
    listItem: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
    },
    listContainer: {
        borderRadius: 6,
    },
    listIcon: {
        width: 24,
        height: 24,
        borderRadius: 50,
        marginRight: 10,
    },
    listText: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
        fontSize: 12,
    },
    dropdownPosition: {
        marginTop: 17,
        width: 120,
        backgroundColor: '#222',
        borderRadius: 6,
    },
    selectedText: {
        color: '#717171',
        fontFamily: 'PP Neue Machina Plain',
    },
    dropdownContainer: {
        width: '50%',
        borderRadius: 6,
    },
});

export default SendScreen;
import React, { useCallback, useState, useEffect } from 'react';
import { Platform, Text, View, StyleSheet, Image, TouchableOpacity } from 'react-native';
import { PublicKey, StakeProgram, ComputeBudgetProgram, VersionedTransaction, TransactionMessage, LAMPORTS_PER_SOL } from '@solana/web3.js';
import LoadingSpinner from './LoadingSpinner';
import LinearGradient from "react-native-web-linear-gradient";
import { usePrivyWalletContext } from './providers/PrivyWalletProvider';
import { useConnection } from './providers/ConnectionProvider';
import BigNumber from 'bignumber.js';

export default function MergeStakeAccounts({ minimumRent }) {
    const { connection } = useConnection();
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rawAccounts, setRawAccounts] = useState([]);
    const [accountsSelected, setAccountsSelected] = useState(0);
    const [publicKey, setPublicKey] = useState(null);
    const { selectedAccount, signAndSendTransaction, stakeAccounts, stakeAccountsLoading, fetchStakeAccounts, rpcCall } = usePrivyWalletContext();

    useEffect(() => {
        if (selectedAccount) {
            setPublicKey(new PublicKey(selectedAccount));
        }
    }, [selectedAccount]);

    const renderStakeAccounts = () => {
        let stAccounts = stakeAccounts;
        stAccounts = stakeAccounts
            .map(account => ({
                ...account,
                isLoading: false,
            }))
            .filter(account => account.state === "Active");
        setAccounts(stAccounts);
        setRawAccounts(stakeAccounts.filter(account => account.state === "Active"));
    };

    useEffect(() => {
        if (stakeAccountsLoading === false) {
            renderStakeAccounts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stakeAccountsLoading, stakeAccounts]);

    const fetchActivationStyle = (state) => {
        let style = null;
        if (state === 'Active') {
            style = styles.green;
        } else if (state === 'Activating' || state === 'Deactivating') {
            style = styles.yellow;
        } else {
            style = styles.red;
        }
        return style;
    };

    const setSelected = useCallback(async (account) => {
        const accounts_clone = accounts
            .map(acc => {
                if (account.pubkey === acc.pubkey) {
                    return {
                        ...acc,
                        isSelected: !acc.isSelected,
                    };
                } else {
                    return acc;
                }
            });
        // Check if any of the accounts are selected.
        let selectedValidator = null;
        for (let i = 0; i <= accounts_clone.length; i++) {
            if (accounts_clone[i] && accounts_clone[i].isSelected === true) {
                selectedValidator = accounts_clone[i].voteAccount;
                break;
            }
        }

        if (selectedValidator) {
            const acctsSelected = accounts_clone.filter(acc => acc.isSelected === true).length;
            setAccountsSelected(acctsSelected);
            if (acctsSelected < 2) {
                setAccounts(accounts_clone.filter(acc => acc.voteAccount === selectedValidator));
            } else {
                setAccounts(accounts_clone.filter(acc => acc.isSelected === true));
            }


        } else {
            setAccounts(rawAccounts);
            setAccountsSelected(0);
        }
    }, [accounts, rawAccounts]);

    const handleButtonPress = useCallback(async () => {
        try {
            setLoading(true);
            const priorityFeeIX = ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 10000 });
            const stakeIX = StakeProgram.merge({
                stakePubkey: new PublicKey(accounts[0].pubkey),
                sourceStakePubKey: new PublicKey(accounts[1].pubkey),
                authorizedPubkey: publicKey,
            }).instructions;
            const {
                value: { blockhash }
            } = await rpcCall(() => connection.getLatestBlockhashAndContext());
            const message = new TransactionMessage({
                payerKey: new PublicKey(selectedAccount),
                recentBlockhash: blockhash,
                instructions: [priorityFeeIX, ...stakeIX],
            }).compileToV0Message();
            const transaction = new VersionedTransaction(message);
            await signAndSendTransaction(transaction, new PublicKey(selectedAccount));

            fetchStakeAccounts();
        } catch (e) {
            console.log(e);
            fetchStakeAccounts();
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicKey, connection, accounts, fetchStakeAccounts]);

    const calculateAmount = (balance) => {
        const bal = new BigNumber(balance);
        return bal.minus(minimumRent).dividedBy(LAMPORTS_PER_SOL).toFixed(5);
    };

    return publicKey !== null ? (
        <View style={styles.container}>
            {accounts.length > 0 ? (
                <View style={styles.container}>
                    <Text numberOfLines={1} style={[styles.stakeAccountHeading]}>Select two stake accounts you would like to merge.</Text>
                </View>
            ) : null}

            {stakeAccountsLoading ? (
                <LoadingSpinner />
            ) : (accounts.length === 0 ? (
                <View style={[styles.container]}>
                    <Text style={[styles.stakeAccountTextHeading, styles.center]}>You do not have any mergable stake accounts.</Text>
                </View>
            ) : accounts.map((account, index) => (
                <LinearGradient
                    key={index}
                    colors={['#4e4e4e4d', '#1d1d1d4d']}
                    start={{ x: 0.5, y: 0 }}
                    end={{ x: 0.5, y: 1 }}
                    style={styles.stakeAccountContainer}
                >
                    <TouchableOpacity key={index} style={[styles.stakeAccount]} onPress={() => setSelected(account)}>
                        <View style={styles.imageContainer}>
                            {account.iconUrl && !account.isSelected ? (
                                <Image
                                    source={{ uri: account.iconUrl }}
                                    defaultSource={account.iconUrl}
                                    style={styles.logo}
                                    onError={() => {
                                        let stakeAccounts = accounts;
                                        stakeAccounts = accounts.map(act => {
                                            if (act.pubkey === account.pubkey) {
                                                return {
                                                    ...act,
                                                    iconUrl: null
                                                }
                                            }
                                            return act;
                                        });
                                        setAccounts(stakeAccounts);
                                    }}
                                />
                            ) : account.iconUrl && account.isSelected ? (
                                <Image source={require("../assets/orange_icons/Check.webp")} style={styles.checkIcon} />
                            ) : (
                                <View style={[styles.logo, styles.emptyLogo]}>
                                    <Text style={styles.emptyLogoText}>V</Text>
                                </View>
                            )}

                        </View>
                        <View style={styles.infoContainer}>
                            <Text style={styles.stakeAccountText} numberOfLines={1}>{account.name}</Text>
                            <Text style={[styles.stakeAccountText, styles.textRight]}>{calculateAmount(account.lamports)} SOL</Text>
                            <Text style={[styles.stakeAccountText, styles.textRight, fetchActivationStyle(account.state)]}>{account.state}</Text>
                        </View>
                    </TouchableOpacity>
                </LinearGradient>
            )))}
            {loading === true ? <LoadingSpinner /> : accounts.length > 0 ? (
                <TouchableOpacity onPress={handleButtonPress} disabled={accountsSelected < 2} style={[styles.stakeButton, accountsSelected < 2 ? styles.disabledButton : null]}>
                    <Text style={styles.blackText}>MERGE</Text>
                </TouchableOpacity>
            ) : null}

        </View>
    ) : (
        <View style={styles.container}>
            <Text style={styles.stakeAccountText}>Please connect your wallet to continue.</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    center: {
        textAlign: 'center',
    },
    disabledButton: {
        opacity: 0.5,
    },
    stakeAccountHeading: {
        marginBottom: 15,
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
        overflow: 'hidden',
        textAlign: 'center',
    },
    stakeAccountContainer: {
        borderRadius: 15,
        width: 400,
        alignItems: 'center',
        justifyContent: 'center',
    },
    stakeAccount: {
        borderWidth: 1,
        borderColor: '#4a4a4a',
        width: '100%',
        maxWidth: 400,
        borderRadius: 15,
        padding: 16,
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'row',
        ...(Platform.OS === 'web' && { flexGrow: 1, flex: 1 }),
        alignItems: 'center',
        marginBottom: 10,
    },
    stakeAccountText: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
        overflow: 'hidden',
        textAlign: 'right',
        marginBottom: 2,
    },
    checkIcon: {
        height: 45,
        width: 45,
    },
    stakeAccountTextHeading: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
        overflow: 'hidden',
        textAlign: 'center',
        marginBottom: 2,
    },
    emptyLogoText: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
    },
    textRight: {
        textAlign: 'right'
    },
    logo: {
        width: 48,
        height: 48,
        borderRadius: 50,
        justifyContent: 'center',
        alignItems: 'center',
    },
    emptyLogo: {
        backgroundColor: 'grey',
    },
    imageContainer: {
        paddingRight: 30,
    },
    infoContainer: {
        paddingRight: 30,
    },
    blackText: {
        color: 'black',
        fontFamily: 'PP Neue Machina Plain',
    },
    stakeButton: {
        backgroundColor: '#d9d9d9',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 12,
        borderRadius: 25,
        width: 400,
    },
    green: {
        color: 'green',
    },
    yellow: {
        color: 'yellow',
    },
    red: {
        color: 'red',
    }
});
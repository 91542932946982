import React, { useState, useEffect, useCallback } from 'react';
import { Platform, View, Text, TextInput, TouchableOpacity, StyleSheet } from "react-native";
import LinearGradient from "react-native-web-linear-gradient";
import { PublicKey, Keypair, StakeProgram, ComputeBudgetProgram, LAMPORTS_PER_SOL, TransactionMessage, VersionedTransaction } from '@solana/web3.js';
import { useConnection } from './providers/ConnectionProvider';
import LoadingSpinner from './LoadingSpinner';
import { usePrivyWalletContext } from './providers/PrivyWalletProvider';
import BigNumber from 'bignumber.js';

export default function StakeCard({ setTxHash, setResult }) {
    const { selectedAccount, signAndSendTransaction, balance, stakeRentExemptMin, apy, rpcCall } = usePrivyWalletContext();
    const [userBalance, setUserBalance] = useState(balance);
    const [stakeAmount, setStakeAmount] = useState('0');
    const [loading, setLoading] = useState(false);
    const [publicKey, setPublicKey] = useState(null);
    const { connection } = useConnection();
    const voteAccount = "oRAnGeU5h8h2UkvbfnE5cjXnnAa4rBoaxmS4kbFymSe";

    // useFocusEffect(
    //     useCallback(() => {
    //         setStakeAmount('0');
    //         setResult('');
    //         setTxHash('');
    //     }, [])
    // );

    useEffect(() => {
        if (selectedAccount) {
            setPublicKey(new PublicKey(selectedAccount));
        }
    }, [selectedAccount]);

    const handleStakeAmount = (text) => {
        // Replace all characters except digits and a single decimal point
        const stakeAmount = text.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
        setStakeAmount(stakeAmount);
    };

    const handleMaxPress = () => {
        if (!selectedAccount) {
            setStakeAmount('0');
        } else {
            const baseTransactionFeeLamports = 5000;
            const totalFeeLamports = (200000 * 10) + baseTransactionFeeLamports;

            const stakeAmount = new BigNumber(userBalance)
                .minus(stakeRentExemptMin) // Subtract rent-exempt balance
                .minus(totalFeeLamports) // Subtract exact transaction + compute costs
                .dividedBy(LAMPORTS_PER_SOL); // Convert to SOL

            // Ensure stakeAmount is non-negative
            const finalAmount = BigNumber.max(0, stakeAmount);

            setStakeAmount(finalAmount.toFixed(9)); // Use 9 decimals for max precision
        }
    };

    const handleStakePress = useCallback(async () => {
        try {
            setLoading(true);
            const stAmount = new BigNumber(stakeAmount);
            const stakeAccount = Keypair.generate();
            const priorityFeeIX = ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 10000 });
            const computeUnitLimitIX = ComputeBudgetProgram.setComputeUnitLimit({ units: 200000 });
            const stakeAccountIX = StakeProgram.createAccount({
                fromPubkey: publicKey,
                stakePubkey: stakeAccount.publicKey,
                authorized: {
                    staker: publicKey,
                    withdrawer: publicKey,
                },
                lamports: stAmount.multipliedBy(LAMPORTS_PER_SOL).toNumber(),
            }).instructions;
            const delegateIX = StakeProgram.delegate({
                stakePubkey: stakeAccount.publicKey,
                authorizedPubkey: publicKey,
                votePubkey: new PublicKey(voteAccount),
            }).instructions;
            const {
                value: { blockhash }
            } = await rpcCall(() => connection.getLatestBlockhashAndContext());
            const message = new TransactionMessage({
                payerKey: new PublicKey(selectedAccount),
                recentBlockhash: blockhash,
                instructions: [priorityFeeIX, computeUnitLimitIX, ...stakeAccountIX, ...delegateIX],
            }).compileToV0Message();
            const transaction = new VersionedTransaction(message);
            transaction.sign([stakeAccount]);
            const { sentTxHash, confirmation, simulationError } = await signAndSendTransaction(transaction, new PublicKey(selectedAccount));
            setLoading(false);
            if (simulationError) {
                setResult("Transaction failed to simulate. Make sure you have enough in your balance to proceed.");
            } else if (!confirmation.value.err) {
                const balance = await rpcCall(() => connection.getBalance(publicKey));
                setResult("Transaction successful!");
                setTxHash(sentTxHash);
                setUserBalance(balance);
            } else {
                setResult("Transaction failed.");
            }
        } catch (e) {
            setLoading(false);
            console.error(e.message || e);
            setResult("An unknown error occurred. Please check your wallet history.");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicKey, connection, stakeAmount, voteAccount, setResult, setTxHash]);
    return (
        <View style={styles.container}>
            <LinearGradient
                colors={['#4e4e4e4d', '#1d1d1d4d']}
                start={{ x: 0.5, y: 0 }}
                end={{ x: 0.5, y: 1 }}
                style={styles.stakingCard}
            >
                <Text style={[styles.headerText, styles.header]}>Amount to Stake</Text>
                <View style={styles.inputContainer}>
                    <TextInput
                        style={styles.input}
                        placeholder="0"
                        value={stakeAmount}
                        onChangeText={handleStakeAmount}
                        keyboardType="numeric"
                    />
                    <TouchableOpacity style={styles.maxButton} onPress={handleMaxPress}>
                        <Text style={styles.maxButtonText}>Max</Text>
                    </TouchableOpacity>
                    <Text style={styles.inputSuffix}>SOL</Text>
                </View>
                <View style={styles.apyContainer}>
                    <Text style={styles.apyLabel}>Estimated APY</Text>
                    <Text key={apy} style={styles.apyValue}>{apy}%</Text>
                </View>
            </LinearGradient>
            {loading === false ? (
                <TouchableOpacity disabled={selectedAccount === null || stakeAmount === String(0) || parseFloat(stakeAmount) === 0} onPress={handleStakePress} style={[styles.stakeButton, selectedAccount === null || stakeAmount === String(0) || parseFloat(stakeAmount) === 0 ? styles.disabledButton : null]}>
                    <Text style={styles.stakeButtonText}>STAKE</Text>
                </TouchableOpacity>
            ) : <LoadingSpinner />}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'black',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    header: {
        marginBottom: 15,
    },
    headerText: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
        overflow: 'hidden',
        textAlign: 'left',
        marginBottom: 2,
    },
    stakingCard: {
        borderRadius: 16,
        padding: 24,
        width: '100%',
        maxWidth: 400,
        minWidth: 300,
    },
    inputContainer: {
        position: 'relative',
        marginBottom: 16,
    },
    input: {
        backgroundColor: 'rgba(0,0,0,0.5)',
        borderWidth: 1,
        borderColor: '#717171',
        borderRadius: 25,
        fontFamily: 'PP Neue Machina Plain',
        color: 'white',
        fontSize: 18,
        paddingVertical: 12,
        paddingHorizontal: 16,
    },
    maxButton: {
        position: 'absolute',
        right: 50,
        top: 12,
        backgroundColor: '#d9d9d9',
        paddingVertical: 4,
        paddingHorizontal: 8,
        borderRadius: 4,
    },
    maxButtonText: {
        color: 'black',
        fontFamily: 'PP Neue Machina Plain',
        fontSize: 14,
    },
    inputSuffix: {
        position: 'absolute',
        right: 16,
        top: 17,
        color: '#717171',
        fontFamily: 'PP Neue Machina Plain',
    },
    apyContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    apyLabel: {
        color: '#717171',
        fontFamily: 'PP Neue Machina Plain',
    },
    apyValue: {
        color: 'white',
        fontSize: Platform.OS !== "ios" ? 20 : 16,
        fontFamily: 'PP Neue Machina Plain',
    },
    stakeButton: {
        backgroundColor: '#d9d9d9',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 30,
        alignItems: 'center',
        paddingVertical: 12,
        borderRadius: 25,
        width: '45%',
        maxWidth: 400,
    },
    disabledButton: {
        opacity: 0.5,
    },
    stakeButtonText: {
        color: 'black',
        fontSize: 18,
        fontFamily: 'PP Neue Machina Plain',
        marginRight: 8,
    },
    stakeButtonIcon: {
        marginLeft: 8,
    },
});
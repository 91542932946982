import React, { useState, useEffect, useCallback } from 'react';
import WalletActionButton from '../components/WalletActionButton';
import { Text, View, ScrollView, StyleSheet, Platform, RefreshControl, Image } from "react-native";
import LinearGradient from "react-native-web-linear-gradient";
import { usePrivyWalletContext } from '../components/providers/PrivyWalletProvider';
import { Tooltip } from "react-native-paper";
import BigNumber from "bignumber.js";
import FontAwesome from "@react-native-vector-icons/fontawesome6";

const WalletScreen = () => {
    const { tokenAccountsAndBalance, getBalance, balancesLoaded, selectedAccount } = usePrivyWalletContext();
    const [totalUsdBalance, setTotalUsdBalance] = useState("");
    const [refreshing, setRefreshing] = useState(false);
    const [balances, setBalances] = useState([{
        mint: "So11111111111111111111111111111111111111112",
        amount: 0,
        decimals: 9,
        name: "Solana",
        symbol: "SOL",
        logoURI: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png",
        verified: true,
        price: 0,
    }]);

    const formatTokenBalance = (account) => {
        const balance = new BigNumber(account.amount).dividedBy(new BigNumber(10).pow(account.decimals)).toFormat(5);
        return Number(balance) > 0 || account.mint === "So11111111111111111111111111111111111111112" ? balance : "<0.00001";
    };

    const formatTokenUsdBalance = (account) => {
        return new BigNumber(account.amount)
            .dividedBy(new BigNumber(10).pow(account.decimals))
            .multipliedBy(new BigNumber(account.price))
            .toFormat(2);
    };

    const formatTokenName = (name) => {
        return name.length > 20 ? name.substring(0, 20) + "…" : name;
    }

    const onRefresh = useCallback(async () => {
        setRefreshing(true);
        await getBalance();

        if (balancesLoaded === true) {
            setRefreshing(false);
        }

    }, [balancesLoaded, getBalance]);

    useEffect(() => {
        if (balancesLoaded) {
            setBalances(tokenAccountsAndBalance);
        } else if (!selectedAccount && !balancesLoaded) {
            setBalances([{
                mint: "So11111111111111111111111111111111111111112",
                amount: 0,
                decimals: 9,
                name: "Solana",
                symbol: "SOL",
                logoURI: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png",
                verified: true,
                price: 0,
            }])
        }
    }, [tokenAccountsAndBalance, balancesLoaded, selectedAccount]);

    useEffect(() => {
        if (refreshing || !balancesLoaded) {
            return;
        } else {
            const tokenUsdBalance = tokenAccountsAndBalance
                .map(account => Number(formatTokenUsdBalance(account)))
                .reduce((sum, balance) => sum + balance, 0)
                .toFixed(2);
            setTotalUsdBalance(tokenUsdBalance);
        }
    }, [refreshing, balancesLoaded, tokenAccountsAndBalance]);

    return (
        <ScrollView
            style={styles.scrollViewContainer}
            contentContainerStyle={styles.scrollViewContainerContent}
            refreshControl={
                <RefreshControl onRefresh={onRefresh} refreshing={refreshing} />
            }
        >
            {selectedAccount ? (
                <Text style={[styles.balance]} numberOfLines={1} adjustsFontSizeToFit>
                    ${totalUsdBalance}
                </Text>
            ) : (
                <Text style={[styles.balance]} numberOfLines={1} adjustsFontSizeToFit>
                    $0.00
                </Text>
            )}
            <View style={styles.buttonRow}>
                <WalletActionButton
                    icon="receive"
                    title="RECEIVE"
                    route="Receive"
                />
                <WalletActionButton
                    icon="send"
                    title="SEND"
                    route="Send"
                />
                <WalletActionButton
                    icon="stake"
                    title="STAKE"
                    route="Stake"
                />
                <WalletActionButton
                    icon="fund"
                    title="BUY"
                    route="Fund"
                />
            </View>
            <View style={styles.balances}>
                {(
                    balances.map(account => (
                        <LinearGradient
                            colors={['#4e4e4e4d', '#1d1d1d4d']}
                            start={{ x: 0.5, y: 0 }}
                            end={{ x: 0.5, y: 1 }}
                            style={[styles.assetListing]}
                            key={account.mint}
                        >
                            <View style={styles.imageContainer}>
                                {account.logoURI ? (
                                    <Image
                                        style={styles.logo}
                                        source={{
                                            uri: account.logoURI,
                                            headers: { "User-Agent": "Orangefin-Mobile-App" },
                                        }}
                                        onError={() => {
                                            const newBalances = [...balances];
                                            for (let i = 0; i < newBalances.length; i++) {
                                                if (newBalances[i].mint === account.mint) {
                                                    newBalances[i] = {
                                                        ...newBalances[i],
                                                        logoURI: "",
                                                    }
                                                }
                                            }
                                            setBalances(newBalances);
                                        }}
                                    />
                                ) : (
                                    <View style={[styles.logo, styles.fillerLogo]}>
                                        <Text
                                            numberOfLines={1}
                                            adjustsFontSizeToFit
                                            minimumFontScale={0.5}
                                            allowFontScaling={true}
                                        >
                                            {account.symbol}
                                        </Text>
                                    </View>
                                )}
                                <View style={styles.tokenInfo}>
                                    <View style={styles.tokenName}>
                                        <Text
                                            style={[styles.balanceText]}
                                            numberOfLines={1}
                                            adjustsFontSizeToFit
                                            minimumFontScale={0.5}
                                            allowFontScaling={true}
                                        >{formatTokenName(account.name)}</Text>
                                        {!account.verified && (
                                            <Tooltip
                                                style={styles.toolTip}
                                                title={
                                                    <Text style={styles.toolTip}>Unverified Token</Text>
                                                }
                                                enterTouchDelay={0}
                                            >
                                                <FontAwesome style={styles.warningIcon} iconStyle='solid' name='circle-exclamation' size={15} color={"yellow"} />
                                            </Tooltip>
                                        )}
                                    </View>
                                    <Text
                                        style={[styles.balanceText]}
                                        numberOfLines={1}
                                        adjustsFontSizeToFit
                                        minimumFontScale={0.5}
                                        allowFontScaling={true}
                                    >{formatTokenBalance(account)} {account.symbol}</Text>
                                </View>
                            </View>
                            <View>
                                <Text
                                    style={[styles.balanceText]}
                                >${formatTokenUsdBalance(account)}</Text>
                            </View>
                        </LinearGradient>
                    ))
                )}
            </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    scrollViewContainer: {
        backgroundColor: 'black',
        flex: 1,
        ...(Platform.OS === "ios" && { paddingTop: 30 }),
    },
    scrollViewContainerContent: {
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 15,
        marginLeft: 25,
        marginRight: 25,
        marginBottom: 15,
    },
    skeletonCircle: {
        width: 48,
        height: 48,
        borderRadius: 24,
        backgroundColor: '#E0E0E0',
    },
    skeletonBalance: {
        width: 200,
        height: 40,
        backgroundColor: '#E0E0E0',
        borderRadius: 4,
        marginTop: 20,
    },
    skeletonText: {
        width: 100,
        height: 20,
        backgroundColor: '#E0E0E0',
        borderRadius: 4
    },
    tokenInfo: {
        marginLeft: 10,
        justifyContent: "flex-start",
    },
    tokenName: {
        flexDirection: "row",
    },
    warningIcon: {
        marginLeft: 20,
        marginBottom: 2,
    },
    buttonRow: {
        marginTop: 50,
        maxWidth: 400,
        width: 400,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    toolTip: {
        fontFamily: 'PP Neue Machina Plain',
    },
    balance: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
        fontSize: 55,
        marginTop: 20,
    },
    assetListing: {
        borderWidth: 1,
        borderColor: '#4a4a4a',
        maxWidth: 400,
        width: '100%',
        borderRadius: 15,
        padding: 16,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        ...(Platform.OS === 'web' && { flexGrow: 1, flex: 1 }),
        alignItems: 'center',
        marginBottom: 10,
    },
    balances: {
        width: '100%',
        marginTop: 15,
        marginBottom: 15,
        justifyContent: 'center',
        alignItems: 'center',
    },
    balanceText: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
        textAlign: 'left',
        marginBottom: 2,
    },
    emptyLogoText: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
    },
    logo: {
        width: 48,
        height: 48,
        borderRadius: 50,
        justifyContent: 'center',
        alignItems: 'center',
    },
    fillerLogo: {
        backgroundColor: "#D3D3D3",
        borderRadius: 50,
    },
    imageContainer: {
        paddingRight: 30,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '45%',
    },
    infoContainer: {
        paddingRight: 30,
        width: 200,
    },
});

export default WalletScreen;
import React, { useCallback, useState, useEffect } from 'react';
import { Platform, Text, View, StyleSheet, Image, TouchableOpacity } from 'react-native';
import { PublicKey, StakeProgram, ComputeBudgetProgram, VersionedTransaction, TransactionMessage, LAMPORTS_PER_SOL } from '@solana/web3.js';
import BigNumber from 'bignumber.js';
import LoadingSpinner from './LoadingSpinner';
import LinearGradient from "react-native-web-linear-gradient";
import { usePrivyWalletContext } from './providers/PrivyWalletProvider';
import { useConnection } from './providers/ConnectionProvider';
import StakeAccountStatus from './StakeAccountStatus';

export default function StakeAccounts({ minimumRent }) {
    const { connection } = useConnection();
    const [accounts, setAccounts] = useState([]);
    const [publicKey, setPublicKey] = useState(null);
    const { selectedAccount, signAndSendTransaction, stakeAccounts, stakeAccountsLoading, fetchStakeAccounts, rpcCall } = usePrivyWalletContext();

    useEffect(() => {
        if (selectedAccount) {
            setPublicKey(new PublicKey(selectedAccount));
        }
    }, [selectedAccount]);


    const renderStakeAccounts = () => {
        let stAccounts = stakeAccounts;
        stAccounts = stakeAccounts
            .map(account => ({
                ...account,
                isLoading: false,
            }));
        setAccounts(stAccounts);
    };


    useEffect(() => {
        if (stakeAccountsLoading === false) {
            renderStakeAccounts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stakeAccountsLoading, stakeAccounts]);

    const setDisabled = (state) => {
        let status = true;
        if (state === 'Active' || state === 'Inactive') {
            status = false;
        } else if (state === 'Activating' || state === 'Deactivating') {
            status = true;
        }
        return status;
    };

    const setButtonText = (state, activate = false) => {
        let status = 'Unstake';
        if (state === 'Inactive') {
            status = activate === true ? 'Stake' : 'Withdraw';
        } else {
            status = 'Unstake';
        }
        return status.toLocaleUpperCase();
    };

    const handleButtonPress = useCallback(async (stakeAccountPubkey, state, lamports, voteAccount = null) => {
        try {
            const accounts_clone = accounts.map(account => {
                if (account.pubkey === stakeAccountPubkey) {
                    return {
                        ...account,
                        isLoading: true,
                    };
                } else {
                    return account;
                }
            });
            setAccounts(accounts_clone);
            const priorityFeeIX = ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 10000 });
            let stakeIX;
            if (state === 'Active') {
                stakeIX = StakeProgram.deactivate({
                    stakePubkey: new PublicKey(stakeAccountPubkey),
                    authorizedPubkey: publicKey,
                }).instructions;
            } else if (state === 'Inactive' && !voteAccount) {
                stakeIX = StakeProgram.withdraw({
                    stakePubkey: new PublicKey(stakeAccountPubkey),
                    authorizedPubkey: publicKey,
                    toPubkey: publicKey,
                    lamports,
                }).instructions;
            } else if (state === 'Inactive' && voteAccount) {
                stakeIX = StakeProgram.delegate({
                    stakePubkey: new PublicKey(stakeAccountPubkey),
                    authorizedPubkey: publicKey,
                    votePubkey: new PublicKey("oRAnGeU5h8h2UkvbfnE5cjXnnAa4rBoaxmS4kbFymSe"),
                }).instructions;
            }
            const {
                value: { blockhash }
            } = await rpcCall(() => connection.getLatestBlockhashAndContext());
            const message = new TransactionMessage({
                payerKey: new PublicKey(selectedAccount),
                recentBlockhash: blockhash,
                instructions: [priorityFeeIX, ...stakeIX]
            }).compileToV0Message();
            const transaction = new VersionedTransaction(message);
            await signAndSendTransaction(transaction, new PublicKey(selectedAccount));
            fetchStakeAccounts();
        } catch (e) {
            console.log(e);
            fetchStakeAccounts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicKey, connection, accounts]);

    const calculateAmount = (balance) => {
        const bal = new BigNumber(balance);
        return bal.minus(minimumRent).dividedBy(LAMPORTS_PER_SOL).toFixed(5);
    };

    return publicKey !== null ? (
        <View
            style={styles.container}
        >
            {stakeAccountsLoading ? (
                <LoadingSpinner />
            ) : (accounts.length === 0 ? (
                <View style={styles.container}>
                    <Text style={styles.stakeAccountTextHeading}>You are currently not staking any SOL.</Text>
                </View>
            ) : accounts.map((account, index) => (
                <LinearGradient
                    key={index}
                    colors={['#4e4e4e4d', '#1d1d1d4d']}
                    start={{ x: 0.5, y: 0 }}
                    end={{ x: 0.5, y: 1 }}
                    style={styles.stakeAccount}
                >
                    <View style={styles.imageContainer}>
                        {account.iconUrl ? (
                            <Image
                                source={{ uri: account.iconUrl }}
                                defaultSource={account.iconUrl}
                                style={styles.logo}
                                onError={() => {
                                    let stakeAccounts = accounts;
                                    stakeAccounts = accounts.map(act => {
                                        if (act.pubkey === account.pubkey) {
                                            return {
                                                ...act,
                                                iconUrl: null
                                            }
                                        }
                                        return act;
                                    });
                                    setAccounts(stakeAccounts);
                                }}
                            />
                        ) : (
                            <View style={[styles.logo, styles.emptyLogo]}>
                                <Text style={styles.emptyLogoText}>V</Text>
                            </View>
                        )}

                    </View>
                    <View style={styles.infoContainer}>
                        <Text style={styles.stakeAccountText} numberOfLines={1}>{account.name}</Text>
                        <Text style={[styles.stakeAccountText, styles.textRight]}>{calculateAmount(account.lamports)} SOL</Text>
                        <StakeAccountStatus
                            key={account.pubkey}
                            state={account.state}
                        />
                    </View>
                    <View>
                        {account.isLoading === false ? (
                            <View>
                                {account.state === "Inactive" ? (
                                    <TouchableOpacity key={`${account.pubkey}-stake`} onPress={() => handleButtonPress(account.pubkey, account.state, account.lamports, account.voteAccount)} style={[styles.stakeButton, setDisabled(account.state) === true ? styles.disabledButton : null]} disabled={setDisabled(account.state)}>
                                        <Text style={[styles.blackText, styles.stakeButtonText]}>{setButtonText(account.state, true)}</Text>
                                    </TouchableOpacity>
                                ) : null}

                                <TouchableOpacity key={`${account.pubkey}-withdraw`} onPress={() => handleButtonPress(account.pubkey, account.state, account.lamports)} style={[styles.stakeButton, styles.withdrawButton, setDisabled(account.state) === true ? styles.disabledButton : null]} disabled={setDisabled(account.state)}>
                                    <Text style={[styles.blackText, styles.stakeButtonText]}>{setButtonText(account.state)}</Text>
                                </TouchableOpacity>
                            </View>
                        ) : <LoadingSpinner key={account.pubkey} />
                        }

                    </View>
                </LinearGradient>
            )))}

        </View>
    ) : (
        <View style={styles.container}>
            <Text style={styles.stakeAccountText}>Please connect your wallet to continue.</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {},
    stakeAccount: {
        borderWidth: 1,
        borderColor: '#4a4a4a',
        maxWidth: 400,
        borderRadius: 15,
        padding: 16,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        ...(Platform.OS === 'web' && { flexGrow: 1, flex: 1 }),
        alignItems: 'center',
        marginBottom: 10,
    },
    stakeAccountText: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
        overflow: 'hidden',
        textAlign: 'right',
        marginBottom: 2,
    },
    stakeAccountTextHeading: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
        overflow: 'hidden',
        textAlign: 'center',
        marginBottom: 2,
    },
    emptyLogoText: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
    },
    textRight: {
        textAlign: 'right'
    },
    logo: {
        width: 48,
        height: 48,
        borderRadius: 50,
        justifyContent: 'center',
        alignItems: 'center',
    },
    emptyLogo: {
        backgroundColor: 'grey',
    },
    imageContainer: {
        paddingRight: 30,
    },
    infoContainer: {
        paddingRight: 30,
        maxWidth: 200,
    },
    statusContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row'
    },
    blackText: {
        color: 'black',
        fontFamily: 'PP Neue Machina Plain',
    },
    stakeButtonText: {
        fontSize: 12,
    },
    stakeButton: {
        backgroundColor: '#d9d9d9',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 12,
        borderRadius: 25,
        width: 100,
    },
    withdrawButton: {
        marginTop: 5,
    },
    disabledButton: {
        opacity: 0.5,
    },
    green: {
        color: 'green',
    },
    yellow: {
        color: 'yellow',
    },
    red: {
        color: 'red',
    }
});
import React, { useState, useEffect } from 'react';
import { Platform } from 'react-native';
import QRCodeStyled from 'react-native-qrcode-styled';
import { Text, View, ScrollView, StyleSheet, TouchableOpacity } from "react-native";
import { usePrivyWalletContext } from '../components/providers/PrivyWalletProvider';
import LoadingSpinner from '../components/LoadingSpinner';


const ReceiveScreen = () => {
    const { selectedAccount } = usePrivyWalletContext();
    const [address, setAddress] = useState("");

    useEffect(() => {
        if (selectedAccount) {
            setAddress(selectedAccount);
        }
    }, [selectedAccount]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(selectedAccount);
    };

    return (
        <ScrollView
            style={styles.scrollViewContainer}
            contentContainerStyle={styles.scrollViewContainerContent}
        >
            {address ? (
                <View>
                    <QRCodeStyled
                        data={address}
                        style={styles.svg}
                        padding={20}
                        pieceSize={6}
                        color={'#000'}
                        errorCorrectionLevel={'H'}
                        innerEyesOptions={{
                            borderRadius: 12,
                            color: '#000',
                        }}
                        outerEyesOptions={{
                            borderRadius: 12,
                            color: '#ffa114',
                        }}
                        logo={{
                            href: require("../assets/logos/orangefin_transparent.png"),
                            padding: 4,
                            // scale: 0.8,
                            // hidePieces: false,
                            // ... any other svg Image props (x, y, preserveAspectRatio, opacity, ...etc)
                        }}
                    />
                    <TouchableOpacity style={styles.stakeButton} onPress={() => copyToClipboard()}>
                        <Text style={styles.stakeButtonText}>COPY ADDRESS</Text>
                    </TouchableOpacity>
                </View>
            ) : <LoadingSpinner />}

        </ScrollView>
    );
}

const styles = StyleSheet.create({
    scrollViewContainer: {
        backgroundColor: 'black',
        flex: 1,
        ...(Platform.OS === "ios" && { paddingTop: 30 }),
    },
    scrollViewContainerContent: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        marginTop: 15,
        marginLeft: 25,
        marginRight: 25,
    },
    stakeButton: {
        backgroundColor: '#d9d9d9',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '10%',
        alignItems: 'center',
        paddingVertical: 12,
        borderRadius: 25,
    },
    stakeButtonText: {
        color: 'black',
        fontSize: 18,
        fontFamily: 'PP Neue Machina Plain',
        marginRight: 8,
    },
    svg: {
        backgroundColor: '#fff',
        borderRadius: 20,
        overflow: 'hidden',
    },
    buttonRow: {
        marginTop: 15,
        width: '100%',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    balance: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
        fontSize: 50,
    },
    assetListing: {
        borderWidth: 2,
        borderColor: 'rgba(255,123,34,0.2)',
        maxWidth: 400,
        width: '100%',
        borderRadius: 25,
        padding: 16,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        ...(Platform.OS === 'web' && { flexGrow: 1, flex: 1 }),
        alignItems: 'center',
        marginBottom: 10,
    },
    balances: {
        width: '100%',
        marginTop: 15,
    },
    balanceText: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
        overflow: 'hidden',
        textAlign: 'left',
        marginBottom: 2,
    },
    emptyLogoText: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
    },
    logo: {
        width: 48,
        height: 48,
        borderRadius: 50,
        justifyContent: 'center',
        alignItems: 'center',
    },
    imageContainer: {
        paddingRight: 30,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '55%',
    },
    infoContainer: {
        paddingRight: 30,
        width: 200,
    },
});

export default ReceiveScreen;
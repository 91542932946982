import React, { useState } from 'react';
import { Platform, View, RefreshControl, StyleSheet, ScrollView } from 'react-native';
import MergeStakeAccounts from '../components/MergeStakeAccounts';
import { usePrivyWalletContext } from '../components/providers/PrivyWalletProvider';

export default function MergeStakeScreen() {
    const [refreshing, setRefreshing] = useState(false);
    const { fetchStakeAccounts, stakeRentExemptMin } = usePrivyWalletContext();

    const onRefresh = async () => {
        setRefreshing(true);
        await fetchStakeAccounts();
        setRefreshing(false);
    };

    return (
        <ScrollView
            style={styles.container}
            contentContainerStyle={styles.scrollViewContent}
            refreshControl={
                <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
        >
            <View style={styles.container}>
                <View style={[styles.scrollView]}>
                    <MergeStakeAccounts
                        minimumRent={stakeRentExemptMin}
                    />
                </View>
            </View>
        </ScrollView >
    )
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'black',
        flex: 1,
        ...(Platform.OS === "ios" && { paddingTop: 10 }),
        width: '100%',
    },
    buttonRow: {
        marginTop: 50,
        width: '100%',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginBottom: 15,
    },
    gradient: {
        marginTop: 15,
        borderRadius: 25,
        marginBottom: 10,
        width: '100%',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 16,
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(255,123,34,0.2)',
        zIndex: 2,
    },
    advanced: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
        width: 150,
        maxWidth: 150,
        alignSelf: 'center',
    },
    logoContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    logo: {
        width: 48,
        height: 48,
        justifyContent: 'center',
        alignItems: 'center',
    },
    innerLogo: {
        width: 32,
        height: 32,
        borderRadius: 16,
        backgroundColor: 'black',
        justifyContent: 'center',
        alignItems: 'center',
        transform: [{ rotate: '-45deg' }],
    },
    connectButton: {
        borderWidth: 1,
        borderColor: '#FF8400',
        paddingVertical: 8,
        paddingHorizontal: 16,
        borderRadius: 8,
    },
    connectButtonText: {
        color: '#FF8400',
        fontWeight: 'bold',
    },
    scrollView: {
        flex: 1,
    },
    scrollViewContent: {
        ...(Platform.OS === 'web' && { flexGrow: 1 }),
        padding: Platform.OS === "web" ? 16 : 5,
        alignItems: 'center',
        marginLeft: 15,
        marginRight: 15,
    },
    main: {
        padding: 16,
        alignItems: 'center',
    },
    whiteTitle: {
        fontSize: Platform.OS !== "ios" ? 36 : 24,
        fontFamily: 'PP Neue Machina Plain',
        color: '#FFFFFF',
        textAlign: 'center',
        marginBottom: 24,
    },
    title: {
        fontSize: Platform.OS !== "ios" ? 36 : 24,
        fontFamily: 'PP Neue Machina Plain',
        color: '#FF8400',
        textAlign: 'center',
        marginBottom: 24,
    },
    stakingCard: {
        backgroundColor: 'rgba(255,123,34,0.1)',
        borderRadius: 16,
        padding: 24,
        width: '100%',
        maxWidth: 400,
    },
    resultContainer: {
        borderRadius: 16,
        padding: 16,
        width: '100%',
        maxWidth: 400,
        alignItems: 'center',
    },
    resultWrapper: {
        padding: Platform.OS === "web" ? 16 : 5,
        alignItems: 'center',
    },
    balanceContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 16,
    },
    balanceLabel: {
        color: '#FF8400',
        fontFamily: 'PP Neue Machina Plain',
    },
    balanceValue: {
        color: 'white',
        fontSize: 20,
        fontFamily: 'PP Neue Machina Plain',
    },
    inputContainer: {
        position: 'relative',
        marginBottom: Platform.OS === "web" ? 16 : 5,
    },
    input: {
        backgroundColor: 'rgba(0,0,0,0.5)',
        borderWidth: 1,
        borderColor: 'rgba(255,123,34,0.5)',
        borderRadius: 8,
        color: 'white',
        fontSize: 18,
        paddingVertical: 12,
        paddingHorizontal: 16,
    },
    maxButton: {
        position: 'absolute',
        right: 50,
        top: 12,
        backgroundColor: 'rgba(255,123,34,0.3)',
        paddingVertical: 4,
        paddingHorizontal: 8,
        borderRadius: 4,
    },
    maxButtonText: {
        color: '#FF8400',
        fontWeight: 'bold',
        fontFamily: 'PP Neue Machina Plain',
        fontSize: 14,
    },
    inputSuffix: {
        position: 'absolute',
        right: 16,
        top: 17,
        color: '#FF8400',
        fontFamily: 'PP Neue Machina Plain',
    },
    apyContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 16,
    },
    apyLabel: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
    },
    apyValue: {
        color: 'white',
        fontSize: 20,
        fontFamily: 'PP Neue Machina Plain',
    },
    whiteText: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
    },
    stakeButton: {
        backgroundColor: '#FF8400',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 12,
        borderRadius: 8,
    },
    disabledButton: {
        opacity: 0.5,
    },
    stakeButtonText: {
        color: 'black',
        fontSize: 18,
        fontFamily: 'PP Neue Machina Plain',
        marginRight: 8,
    },
    stakeButtonIcon: {
        marginLeft: 8,
    },
    sectionTitle: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#FF8400',
        textAlign: 'center',
        marginTop: 32,
        marginBottom: 16,
    },
    featuresContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    featureCard: {
        backgroundColor: 'rgba(255,123,34,0.05)',
        borderRadius: 12,
        padding: 16,
        margin: 8,
        width: '30%',
        minWidth: 200,
        alignItems: 'center',
    },
    featureIcon: {
        marginBottom: 8,
    },
    featureTitle: {
        color: '#FF8400',
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 8,
        textAlign: 'center',
    },
    featureDescription: {
        color: 'rgba(255,255,255,0.7)',
        textAlign: 'center',
    },
    footer: {
        borderTopWidth: 1,
        borderTopColor: 'rgba(255,123,34,0.2)',
        padding: 16,
    },
    footerNav: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 8,
    },
    footerLink: {
        color: 'rgba(255,255,255,0.7)',
        marginHorizontal: 8,
    },
    footerText: {
        color: 'rgba(255,255,255,0.5)',
        textAlign: 'center',
    },
});
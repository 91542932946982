import React, { useState, useCallback, useEffect } from 'react';
import { Text, TextInput, View, ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import { usePrivy } from '@privy-io/react-auth';
import { useSolanaWallets } from '@privy-io/react-auth/solana';
import { useNavigation } from '@react-navigation/native';
import { usePrivyWalletContext } from '../components/providers/PrivyWalletProvider';

const EmailLoginScreen = () => {
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [userId, setUserId] = useState('');
    const [error, setError] = useState('');
    const { user } = usePrivy();
    const { wallets } = useSolanaWallets();
    const { selectedAccount, createWallet, sendCode, loginWithCode } = usePrivyWalletContext();
    const navigation = useNavigation();

    // Function to validate an email address
    function isValidEmail(email) {
        if (typeof email !== 'string') {
            return false;
        }

        // Basic email format validation using a regular expression
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            return false;
        }

        // Extract the TLD from the email address
        const domainParts = email.split('.');
        const tld = domainParts[domainParts.length - 1].toLowerCase();

        // Hardcoded list of valid TLDs
        const validTlds = [
            "com", "org", "net", "edu", "gov", "io", "ai", "info", "biz", "xyz", "co", "us", "uk", "de", "jp", "cn", "fr", "au", "it", "es", "me", "ventures", "app", "tech", "dev", "online", "store", "shop", "site", "blog", "live", "media", "agency", "today", "world", "solutions", "digital", "news", "group", "travel", "space", "club", "design", "events", "life", "finance", "email", "studio", "systems", "works", "tools", "software", "network", "services"
        ];

        // Check if the TLD is in the list of valid TLDs
        return validTlds.includes(tld);
    }

    useEffect(() => {
        if (selectedAccount) return;
        if (user) {
            setUserId(user.id);
        } else {
            setUserId(null);
        }

        if (user && user.id && wallets.length > 0) {
            navigation.navigate("Stake");
        }
    }, [user, wallets, selectedAccount, navigation]);

    const handleSendCode = useCallback(async ({ email }) => {
        try {
            await sendCode({ email });
            setEmailSent(true);
        } catch (e) {
            console.log(e);
            setError("Error sending email");
        }
    }, [sendCode]);

    const login = useCallback(async ({ code, email }) => {
        try {
            await loginWithCode({
                code,
                email,
            });
            setOtp('');
            setUserId('');
            setError('');
            setEmailSent(false);
        } catch (e) {
            console.log(e);
            setError(e.message);
        }
    }, [loginWithCode]);

    const headerText = useCallback(() => {
        let text = "";
        if (!emailSent && !userId) {
            text = "Enter your email to sign in or sign up!";
        } else if (emailSent && !userId) {
            text = "Enter the code you received in your email."
        } else if (userId && wallets.length === 0) {
            text = "Click the button below to create your wallet!";
        }
        return text;
    }, [userId, emailSent, wallets])

    return (
        <ScrollView
            style={styles.scrollViewContainer}
            contentContainerStyle={styles.scrollViewContainerContent}
        >
            {!userId ? (
                <View style={styles.container}>
                    <Text style={styles.text}>{headerText()}</Text>
                    <TextInput
                        style={styles.input}
                        value={!emailSent ? email : otp}
                        onChangeText={!emailSent ? setEmail : setOtp}
                        placeholder={!emailSent ? "Email" : "Code"}
                    />
                    <TouchableOpacity
                        style={[styles.loginButton, !emailSent && !isValidEmail(email) ? styles.disabledButton : null]}
                        onPress={() => !emailSent ? handleSendCode({ email }) : login({ email, code: otp })}
                        disabled={!isValidEmail(email)}
                    >
                        <Text style={styles.buttonText}>{!emailSent ? "GET STARTED" : "LOGIN"}</Text>
                    </TouchableOpacity>
                    <Text style={[styles.errorText]}>{error}</Text>
                </View>
            ) : wallets.length === 0 ? (
                <View style={styles.container}>
                    <TouchableOpacity style={styles.loginButton} onPress={() => createWallet()}>
                        <Text style={styles.buttonText}>Create Wallet</Text>
                    </TouchableOpacity>
                </View>
            ) : null}
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    scrollViewContainer: {
        backgroundColor: 'black',
        flex: 1,
        paddingTop: 50,
    },
    scrollViewContainerContent: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    input: {
        backgroundColor: 'rgba(0,0,0,0.5)',
        borderWidth: 1,
        borderColor: '#717171',
        borderRadius: 25,
        fontFamily: 'PP Neue Machina Plain',
        color: 'white',
        fontSize: 18,
        marginVertical: 15,
        paddingVertical: 12,
        paddingHorizontal: 16,
        width: '75%',
    },
    buttonText: {
        fontFamily: 'PP Neue Machina Plain',
        color: 'black',
    },
    disabledButton: {
        opacity: 0.5,
    },
    errorText: {
        color: 'red',
        fontFamily: 'PP Neue Machina Plain',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        marginBottom: 2,
        paddingTop: 10,
    },
    text: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        marginBottom: 2,
    },
    loginButton: {
        backgroundColor: '#d9d9d9',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 12,
        borderRadius: 25,
        width: '75%',
    }
});

export default EmailLoginScreen;
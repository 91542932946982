import React, { useEffect, useState, createContext, useContext } from 'react';

export const FundingMethodContext = createContext({
    provider: "moonpay",
    setProvider: (test) => { }
});

export function FundingMethodProvider({ children }) {
    const [provider, setProvider] = useState("moonpay");

    useEffect(() => {
        setProvider(provider);
    }, [provider]);

    return (
        <FundingMethodContext.Provider value={{ provider, setProvider }}>
            {children}
        </FundingMethodContext.Provider>
    );
}

export function useFundingMethodProvider() {
    return useContext(FundingMethodContext);
}

import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLoginWithOAuth, useLoginWithPasskey, useSignupWithPasskey, usePrivy, useLoginWithEmail, useLinkWithPasskey } from "@privy-io/react-auth";
import { useSolanaWallets, useFundWallet } from '@privy-io/react-auth/solana';
import { useNavigation } from '@react-navigation/native';
import { useConnection } from './ConnectionProvider';
import { BIRDEYE_API_KEY, BIRDEYE_REFERER } from "@env";
import {
    PublicKey,
    StakeProgram,
} from '@solana/web3.js';

const PrivyWalletContext = createContext(null);

export const WALLET_TYPES = {
    NONE: 0,
    EMBEDDED: 1,
    EXTERNAL: 2,
};

export function PrivyWalletProvider({ children }) {
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [walletType, setWalletType] = useState(WALLET_TYPES.NONE);
    const [balancesLoaded, setBalancesLoaded] = useState(false);
    const [balance, setBalance] = useState(0);
    const [tokenAccountsAndBalance, setTokenAccountsAndBalance] = useState([]);
    const [validatorInfo, setValidatorInfo] = useState([]);
    const [stakeAccounts, setStakeAccounts] = useState([]);
    const [stakeAccountsLoading, setStakeAccountsLoading] = useState(true);
    const [stakeRentExemptMin, setStakeRentExemptMin] = useState(0);
    const [apy, setApy] = useState(10.0);
    const { initOAuth } = useLoginWithOAuth();
    const { loginWithPasskey } = useLoginWithPasskey();
    const { signupWithPasskey } = useSignupWithPasskey();
    const { user, login: loginWithPrivyUI, ready, connectWallet, authenticated, logout: logoutPrivy } = usePrivy();
    const { createWallet, wallets, exportWallet } = useSolanaWallets();
    const { sendCode, loginWithCode } = useLoginWithEmail();
    const { fundWallet } = useFundWallet();
    const navigation = useNavigation();
    const { connection } = useConnection();
    const { linkWithPasskey } = useLinkWithPasskey();

    useEffect(() => {
        if (user && wallets.length > 0) {
            setSelectedAccount(user.wallet.address);
        }
    }, [user, wallets]);

    useEffect(() => {
        if (user && !user.wallet) {
            navigation.navigate("CreateWallet");
        }
    }, [user, navigation]);

    useEffect(() => {
        if (ready && user && user.wallet?.connectorType === "solana_adapter") {
            connectWallet({
                suggestedAddress: user.wallet?.address,
                walletList: [user.wallet?.walletClientType],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ready, user]);

    useEffect(() => {
        if (!ready || !authenticated || !user.wallet) return;
        const wallet = user.linkedAccounts.filter(account => account.address === user.wallet.address)[0];
        if (wallet.type === "wallet" && wallet.walletClientType === "privy" && wallet.connectorType === "embedded") {
            setWalletType(WALLET_TYPES.EMBEDDED);
        } else if (wallet.type === "wallet" && wallet.connectorType === "solana_adapter") {
            setWalletType(WALLET_TYPES.EXTERNAL);
        }
    }, [user, ready, authenticated]);

    const fetchApy = async () => {
        const request = await fetch('https://api.stakewiz.com/validator/oRAnGeU5h8h2UkvbfnE5cjXnnAa4rBoaxmS4kbFymSe');
        const data = await request.json();
        setApy(data.total_apy);
    };

    const getMinimumBalanceForRentExemption = async () => {
        const amount = await rpcCall(() => connection.getMinimumBalanceForRentExemption(StakeProgram.space));
        setStakeRentExemptMin(amount);
    };

    const login = async (method) => {
        try {
            if (method === "google") {
                await initOAuth({ provider: "google" });
            } else if (method === "apple") {
                await initOAuth({ provider: "apple" });
            } else if (method === "passkeyLogin") {
                await loginWithPasskey();
            } else if (method === "passkeySignup") {
                await signupWithPasskey();
            } else if (method === "externalWallet") {
                await loginWithPrivyUI({ loginMethods: ['wallet'] })
            }
        } catch (e) {
            console.log(e);
        }
    };

    const logout = async () => {
        try {
            await logoutPrivy();
            setSelectedAccount(null);
            setWalletType(WALLET_TYPES.NONE);
            setValidatorInfo([]);
            setStakeAccounts([]);
            setBalance(0);
            setTokenAccountsAndBalance([{
                mint: "So11111111111111111111111111111111111111112",
                amount: 0,
                decimals: 9,
                name: "Solana",
                symbol: "SOL",
                logoURI: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png",
                verified: true,
                price: 0,
            }]);
        } catch (e) {
            console.log(e);
        }
    };

    async function rpcCall(rpcFunc, args = [], retries = 5, delay = 500, backoffFactor = 2) {
        for (let attempt = 1; attempt <= retries; attempt++) {
            try {
                return await rpcFunc(...args);
            } catch (error) {
                console.error(`Attempt ${attempt} failed: ${error.message}`);

                if (attempt === retries) throw error;

                const waitTime = delay * Math.pow(backoffFactor, attempt - 1);
                console.log(`Retrying in ${waitTime}ms...`);

                await new Promise(res => setTimeout(res, waitTime));
            }
        }
    };

    const signAndSendTransaction = async (tx, signer) => {
        let sentTxHash;
        let confirmation;
        try {
            sentTxHash = await wallets[0].sendTransaction(tx, connection);
            confirmation = await rpcCall(() => connection.confirmTransaction(sentTxHash));
            return {
                sentTxHash,
                confirmation,
                simulationError: null,
            };
        } catch (e) {
            console.log("error sign and send tx", e);
            return;
        } finally {
            // refresh balance and stake accounts after every tx
            await getBalance();
            await fetchStakeAccounts();
        }

    };

    const getBalance = async () => {
        if (!selectedAccount) return;
        try {
            setBalancesLoaded(false);
            const balance = await rpcCall(() => connection.getBalance(new PublicKey(selectedAccount)));
            await getTokenAccounts();
            setBalance(balance);

        } catch (e) {
            console.log("Error fetching balance", e);
        } finally {
            setBalancesLoaded(true);
        }
    };

    const getTokenAccounts = async () => {
        const accountsPromise = connection.getParsedProgramAccounts(
            new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"),
            {
                filters: [
                    { dataSize: 165 },  // Size of account (bytes)
                    { memcmp: { offset: 32, bytes: selectedAccount } }  // Search criteria
                ],
            },
        );

        const balancePromise = rpcCall(() => connection.getBalance(new PublicKey(selectedAccount)));
        const accounts = (await accountsPromise).filter(account =>
            Number(account.account.data["parsed"]["info"]["tokenAmount"]["amount"]) !== 0
        );

        // Extract mint addresses
        let mints = accounts.map(acc => acc.account.data["parsed"]["info"]["mint"]);
        mints.push("So11111111111111111111111111111111111111112");
        // Fetch token prices + token metadata in parallel
        const birdeyePricesUrl = `https://public-api.birdeye.so/defi/multi_price?list_address=${mints.join(",")}`;
        const [pricesData, tokensData, balance] = await Promise.all([
            fetch(birdeyePricesUrl, {
                headers: {
                    "X-API-KEY": BIRDEYE_API_KEY,
                    "Referer": BIRDEYE_REFERER,
                },
            }).then(res => res.json()),
            getTokenMetadata(mints),
            balancePromise,
        ]);



        // Parse account data
        let parsedAccounts = accounts
            .map((account, i) => {
                const mint = account.account.data["parsed"]["info"]["mint"];
                const tokenData = tokensData.filter(token => token.mint === mint)[0];
                return {
                    mint,
                    amount: account.account.data["parsed"]["info"]["tokenAmount"]["amount"],
                    decimals: account.account.data["parsed"]["info"]["tokenAmount"]["decimals"],
                    name: tokenData?.name || "Unknown",
                    symbol: tokenData?.symbol || "Unknown",
                    logoURI: tokenData?.logoURI || "",
                    verified: tokenData?.verified,
                    price: tokenData?.verified ? pricesData.data[mint]?.value ?? "0" : "0",
                };
            })
            .filter(account => account.symbol !== "Unknown" || account.name !== "Unknown");

        // Push SOL
        parsedAccounts.push({
            mint: "So11111111111111111111111111111111111111112",
            amount: balance,
            decimals: 9,
            name: "Solana",
            symbol: "SOL",
            logoURI: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png",
            verified: true,
            price: pricesData.data["So11111111111111111111111111111111111111112"].value,
        });

        // Sort accounts by value
        const sortedAccounts = parsedAccounts.sort((a, b) => (
            (Number(b.amount) / Math.pow(10, b.decimals)) * Number(b.price) -
            (Number(a.amount) / Math.pow(10, a.decimals)) * Number(a.price)
        ));

        setTokenAccountsAndBalance(sortedAccounts);
        await setCacheTokenMetadata(sortedAccounts.map(account => ({
            name: account.name,
            symbol: account.symbol,
            verified: account.verified,
            logoURI: account.logoURI,
            mint: account.mint,
        })));
    };

    const getTokenMetadata = async (mints) => {
        const cache = JSON.parse(await getCacheTokenMetadata()) || [];
        const unknownAssetMints = [];
        const cachedAssetMints = cache.map(token => token.mint);
        for (let i = 0; i < mints.length; i++) {
            if (cachedAssetMints && cachedAssetMints.includes(mints[i])) {
                continue;
            } else {
                unknownAssetMints.push(mints[i]);
            }
        }
        // if no unknown assets, just resolve with the cache
        if (unknownAssetMints.length > 0) {
            const jupTokens = await Promise.all(
                unknownAssetMints.map(asset => fetch(`https://api.jup.ag/tokens/v1/token/${asset}`).then(res => res.json()))
            );
            for (let i = 0; i < jupTokens.length; i++) {
                cache.push({
                    name: jupTokens[i].name,
                    symbol: jupTokens[i].symbol,
                    logoURI: jupTokens[i].logoURI,
                    mint: jupTokens[i].address,
                    verified: jupTokens[i].tags?.includes("verified"),
                });
            }
        }
        return Promise.resolve(cache);
    };

    const getCacheTokenMetadata = async () => {
        return Promise.resolve("[{}]");
    };

    const setCacheTokenMetadata = async () => {
        return true;
    };

    const fetchValidatorInfo = async () => {
        if (validatorInfo.length > 0) {
            return;
        }

        try {
            // Fetch the validator info accounts
            const accounts = await rpcCall(() => connection.getParsedProgramAccounts(
                new PublicKey("Config1111111111111111111111111111111111111"),
                {
                    filters: [
                        { memcmp: { offset: 1, bytes: new PublicKey("Va1idator1nfo111111111111111111111111111111").toString() } },
                    ]
                }
            ));
            // Fetch the vote accounts from Solana
            const voteAccountsData = await rpcCall(() => connection.getVoteAccounts());
            const allVoteAccounts = voteAccountsData.current.concat(voteAccountsData.delinquent);

            const validators = accounts.map(account => {
                const parsed = (account.account.data).parsed;
                if (!account.account || !account.account.data || !parsed) {
                    return {};
                }
                const identityKey = parsed.info.keys[1].pubkey;
                const voteAccountInfo = allVoteAccounts.find(voteAccount => voteAccount.nodePubkey === identityKey);
                const info = parsed.info.configData;


                // Match the vote account for this identity key

                let iconUrl = null;
                if (info.iconUrl) {
                    iconUrl = info.iconUrl;
                }

                return {
                    identityKey,
                    voteAccount: voteAccountInfo ? voteAccountInfo.votePubkey : null,
                    name: info.name,
                    iconUrl: iconUrl,
                };
            });
            setValidatorInfo(validators);
        } catch (error) {
            console.error('Error fetching validator info:', error);
        }
    };

    const fetchStakeAccounts = async () => {
        setStakeAccountsLoading(true);
        const epochInfo = await rpcCall(() => connection.getEpochInfo());
        const currentEpoch = epochInfo.epoch;
        // Get the list of stake accounts
        const rawStakeAccounts = await rpcCall(() => connection.getParsedProgramAccounts(
            new PublicKey('Stake11111111111111111111111111111111111111'), // Solana Stake Program ID
            {
                filters: [
                    {
                        memcmp: {
                            offset: 12, // The offset where the stake authority's public key is stored
                            bytes: new PublicKey(selectedAccount).toString(),
                        },
                    },
                ],
            }
        ));
        // Log or return the stake accounts
        const stAccounts = rawStakeAccounts.map(account => {
            const parsed = (account.account.data).parsed;
            const stakeData = parsed.info.stake.delegation;
            let state = 'Inactive';

            if (stakeData) {
                let { stake, activationEpoch, deactivationEpoch } = stakeData;
                activationEpoch = parseFloat(activationEpoch);
                deactivationEpoch = parseFloat(deactivationEpoch);
                if (stake > 0) {
                    if (activationEpoch < currentEpoch && (deactivationEpoch === '18446744073709551615' || deactivationEpoch > currentEpoch)) {
                        state = 'Active';
                    } else if (activationEpoch === currentEpoch) {
                        state = 'Activating';
                    } else if (deactivationEpoch === currentEpoch && deactivationEpoch !== '18446744073709551615') {
                        state = 'Deactivating';
                    }
                }
            }
            const voteAccount = parsed.info.stake.delegation.voter;
            return {
                name: validatorInfo.find(info => info.voteAccount === voteAccount).name,
                iconUrl: validatorInfo.find(info => info.voteAccount === voteAccount).iconUrl,
                pubkey: account.pubkey.toString(),
                voteAccount: voteAccount,
                lamports: account.account.lamports,
                state: state,
            }
        });
        setStakeAccounts(stAccounts);
        setStakeAccountsLoading(false);
    };


    useEffect(() => {
        if (!selectedAccount) return;

        getBalance();
        fetchValidatorInfo();
        getMinimumBalanceForRentExemption();
        fetchApy();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount]);

    useEffect(() => {
        if (validatorInfo.length > 0) {
            fetchStakeAccounts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validatorInfo]);

    return (
        <PrivyWalletContext.Provider value={{
            selectedAccount,
            getBalance,
            login,
            createWallet,
            sendCode,
            loginWithCode,
            walletType,
            logout,
            exportWallet,
            signAndSendTransaction,
            balancesLoaded,
            tokenAccountsAndBalance,
            balance,
            apy,
            stakeAccounts,
            fetchStakeAccounts,
            stakeAccountsLoading,
            stakeRentExemptMin,
            fundWallet,
            linkWithPasskey,
            rpcCall,
        }}>
            {children}
        </PrivyWalletContext.Provider>
    );
}

export function usePrivyWalletContext() {
    return useContext(PrivyWalletContext);
}

import React, { useState } from 'react';
import { Platform, View, StyleSheet, ScrollView, RefreshControl } from 'react-native';
import StakeAccounts from '../components/StakeAccounts';
import { usePrivyWalletContext } from '../components/providers/PrivyWalletProvider';

export default function StakeScreen() {
    const [refreshing, setRefreshing] = useState(false);
    const { fetchStakeAccounts, stakeRentExemptMin } = usePrivyWalletContext();

    const onRefresh = async () => {
        setRefreshing(true);
        await fetchStakeAccounts();
        setRefreshing(false);
    };

    return (
        <ScrollView
            style={styles.container}
            contentContainerStyle={styles.scrollViewContent}
            refreshControl={
                <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
        >
            <View style={styles.container}>
                <View style={[styles.scrollView]}>
                    <StakeAccounts
                        minimumRent={stakeRentExemptMin}
                    />
                </View>
            </View>
        </ScrollView >
    )
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'black',
        flex: 1,
        ...(Platform.OS === "ios" && { paddingTop: 10 }),
        width: '100%',
    },
    scrollView: {
        flex: 1,
        alignItems: 'center',
    },
    scrollViewContent: {
        ...(Platform.OS === 'web' && { flexGrow: 1 }),
        padding: Platform.OS === "web" ? 16 : 5,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 15,
        marginRight: 15,
    },
});
import '@tiplink/wallet-adapter-react-ui/styles.css';
import './App.css';

import React, { useState } from 'react';
import { Image, View, TouchableOpacity, Text, Linking, StyleSheet } from 'react-native-web';
import { PaperProvider } from 'react-native-paper';
import { PrivyWalletProvider } from './components/providers/PrivyWalletProvider';
import { ConnectionProvider } from './components/providers/ConnectionProvider';
import { FundingMethodProvider } from './components/providers/FundingMethodProvider';
import StakeScreen from './screens/StakeScreen';
import WalletScreen from './screens/WalletScreen';
import LoginMethodsScreen from "./screens/LoginMethodsScreen";
import CreateWalletScreen from "./screens/CreateWalletScreen";
import ReceiveScreen from "./screens/ReceiveScreen";
import UnstakeScreen from "./screens/UnstakeScreen";
import MergeStakeScreen from "./screens/MergeStakeScreen";
import EmailLoginScreen from "./screens/EmailLoginScreen";
import SendScreen from "./screens/SendScreen";
import FundScreen from "./screens/FundScreen";
import SettingsScreen from "./screens/SettingsScreen";
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { Platform } from 'react-native';
import 'fast-text-encoding';
import 'react-native-get-random-values';
import { Buffer } from "buffer";
import '@ethersproject/shims';
import { PrivyProvider } from '@privy-io/react-auth';
import { toSolanaWalletConnectors } from '@privy-io/react-auth/solana';
import "@simplewebauthn/browser";

global.Buffer = Buffer;


const ConnectWallet = Platform.OS === "web" ? require("./components/ConnectWallet").default : null;
const registerTipLinkWallet = Platform.OS === "web" ? require("@tiplink/wallet-adapter").registerTipLinkWallet : null;
const handleHomePress = () => {
    Linking.openURL('https://www.orangefin.ventures');
};
const rpcUrl = "https://cindie-7oi4z0-fast-mainnet.helius-rpc.com";
if (Platform.OS === "web") {
    registerTipLinkWallet({
        title: "Orangefin",
        clientId: "100b0fb4-1fcf-4f48-b702-1725cabda6f9",
        theme: "dark",
        rpcUrl: rpcUrl,
    });
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'black',
    },
    header: {
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        backgroundColor: 'black',
        flex: 1,
    },
    headerLeft: {
        flexDirection: 'row',
        alignItems: 'center',
        width: Platform.OS !== "ios" ? 48 : 40,
        height: Platform.OS !== "ios" ? 48 : 40,
    },
    logo: {
        width: Platform.OS !== "ios" ? 48 : 40,
        height: Platform.OS !== "ios" ? 48 : 40,
        justifyContent: 'center',
        alignItems: 'center',
    },
    connectWallet: {
        padding: Platform.OS !== "ios" ? 16 : 0,
    },
    hamburger: {
        padding: 10,
        marginLeft: 10,
        backgroundColor: 'black',
        borderRadius: 5,
    },
    hamburgerText: { color: 'white', fontSize: 24 },
    drawerIcon: {
        height: 30,
        width: 30,
        resizeMode: 'contain',
    }
});

const headerOptions = (email, setEmail, navigation) => ({
    headerStyle: styles.header,
    headerTintColor: '#fff',
    headerLeft: () => (
        <View style={styles.headerLeft}>
            <TouchableOpacity onPress={() => navigation.toggleDrawer()} style={styles.hamburger}>
                <Text style={styles.hamburgerText}>☰</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={handleHomePress}>
                <Image
                    source={{ uri: "https://img.orangefin.ventures/orangefin_full_black.png" }}
                    defaultSource="https://img.orangefin.ventures/orangefin_full_black.png"
                    style={styles.logo}
                />
            </TouchableOpacity>
        </View>
    ),
    headerRight: () => Platform.OS === "web" ? (
        <View style={styles.connectWallet}>
            <ConnectWallet style={styles.connectWallet} />
        </View>
    ) : (
        <View style={styles.connectWallet}>
        </View>
    ),
    headerTitle: "",
});

const Drawer = createDrawerNavigator({
    // initialRouteName: 'Stake',
    // screens: {
    //     Stake: HomeScreen,
    //     Wallet: WalletScreen,
    // }
});

export default function App() {
    const [email, setEmail] = useState('');

    return (
        <NavigationContainer>
            <PrivyProvider
                appId='cm66h9io002u2n1iv6z9w7h8z'
                clientId='client-WY5g5TsAGWWgigFyDvnpW4QLMDX4tdNxG18Z3e2CRGyJu'
                config={{
                    solanaClusters: [{ name: 'mainnet-beta', rpcUrl: 'https://api.mainnet-beta.solana.com' }],
                    loginMethods: ["email", "apple", "google", "wallet", "passkey"],
                    appearance: {
                        walletList: ['detected_solana_wallets'],
                        walletChainType: 'solana-only',
                    },
                    externalWallets: {
                        solana: {
                            connectors: toSolanaWalletConnectors({
                                shouldAutoConnect: true,
                            })
                        }
                    },
                    embeddedWallets: {
                        solana: {
                            createOnLogin: "users-without-wallets",
                        }
                    }
                }}
            >
                <ConnectionProvider>
                    <FundingMethodProvider>
                        <PrivyWalletProvider>
                            <PaperProvider>
                                <Drawer.Navigator
                                    screenOptions={{
                                        drawerStyle: {
                                            backgroundColor: 'black',
                                        },
                                        drawerLabelStyle: {
                                            color: 'white',
                                        },
                                        drawerActiveBackgroundColor: "transparent",
                                        drawerInactiveBackgroundColor: "transparent",
                                        drawerActiveTintColor: "white",
                                        drawerInactiveTintColor: "white",
                                        drawerItemStyle: {
                                            fontFamily: 'PP Neue Machina Plain'
                                        }
                                    }}
                                >
                                    <Drawer.Screen
                                        name="Stake"
                                        component={StakeScreen}
                                        options={({ navigation }) => ({
                                            ...headerOptions(email, setEmail, navigation), // Keep your header options
                                            drawerLabel: "Stake",
                                            drawerIcon: ({ size }) => (
                                                <Image source={require("./assets/orange_icons/stake_active.webp")} style={styles.drawerIcon} />
                                            )
                                        })}
                                    />
                                    <Drawer.Screen
                                        name="Wallet"
                                        component={WalletScreen}
                                        options={({ navigation }) => ({
                                            ...headerOptions(email, setEmail, navigation), // Keep your header options
                                            drawerLabel: "Wallet",
                                            drawerIcon: ({ focused, size }) => (
                                                <Image source={require("./assets/orange_icons/Wallet.webp")} style={styles.drawerIcon} />
                                            ),
                                        })}
                                    />
                                    <Drawer.Screen
                                        name="Send"
                                        component={SendScreen}
                                        options={({ navigation }) => ({
                                            ...headerOptions(email, setEmail, navigation), // Keep your header options
                                            drawerLabel: "Send",
                                            drawerIcon: ({ size }) => (
                                                <Image source={require("./assets/orange_icons/Send.webp")} style={styles.drawerIcon} />
                                            )
                                        })}
                                    />
                                    <Drawer.Screen
                                        name="Fund"
                                        component={FundScreen}
                                        options={({ navigation }) => ({
                                            ...headerOptions(email, setEmail, navigation), // Keep your header options
                                            drawerLabel: "Buy",
                                            drawerIcon: ({ size }) => (
                                                <Image source={require("./assets/orange_icons/Fund.webp")} style={styles.drawerIcon} />
                                            )
                                        })}
                                    />
                                    <Drawer.Screen
                                        name="Settings"
                                        component={SettingsScreen}
                                        options={({ navigation }) => ({
                                            ...headerOptions(email, setEmail, navigation), // Keep your header options
                                            drawerLabel: "Settings",
                                            drawerIcon: ({ size }) => (
                                                <Image source={require("./assets/orange_icons/Gear.webp")} style={styles.drawerIcon} />
                                            )
                                        })}
                                    />
                                    <Drawer.Screen
                                        name="Receive"
                                        component={ReceiveScreen}
                                        options={({ navigation }) => ({
                                            ...headerOptions(email, setEmail, navigation), // Keep your header options
                                            drawerLabel: "Stake",
                                            drawerItemStyle: { display: 'none' }, // Hides from drawer
                                            title: "Receive",
                                        })}
                                    />
                                    <Drawer.Screen
                                        name="Unstake"
                                        component={UnstakeScreen}
                                        options={({ navigation }) => ({
                                            ...headerOptions(email, setEmail, navigation), // Keep your header options
                                            drawerLabel: "Stake",
                                            drawerItemStyle: { display: 'none' }, // Hides from drawer
                                            title: "Unstake",
                                        })}
                                    />
                                    <Drawer.Screen
                                        name="Merge"
                                        component={MergeStakeScreen}
                                        options={({ navigation }) => ({
                                            ...headerOptions(email, setEmail, navigation), // Keep your header options
                                            drawerLabel: "Stake",
                                            drawerItemStyle: { display: 'none' }, // Hides from drawer
                                            title: "Merge",
                                        })}
                                    />
                                    <Drawer.Screen
                                        name="LoginMethods"
                                        component={LoginMethodsScreen}
                                        options={({ navigation }) => ({
                                            ...headerOptions(email, setEmail, navigation), // Keep your header options
                                            drawerLabel: "Stake",
                                            drawerItemStyle: { display: 'none' }, // Hides from drawer
                                            title: "LoginMethods",
                                        })}
                                    />
                                    <Drawer.Screen
                                        name="CreateWallet"
                                        component={CreateWalletScreen}
                                        options={({ navigation }) => ({
                                            ...headerOptions(email, setEmail, navigation), // Keep your header options
                                            drawerLabel: "Create Wallet",
                                            drawerItemStyle: { display: 'none' }, // Hides from drawer
                                            title: "CreateWallet",
                                        })}
                                    />
                                    <Drawer.Screen
                                        name="EmailLogin"
                                        component={EmailLoginScreen}
                                        options={({ navigation }) => ({
                                            ...headerOptions(email, setEmail, navigation), // Keep your header options
                                            drawerLabel: "Email Login",
                                            drawerItemStyle: { display: 'none' }, // Hides from drawer
                                            title: "EmailLogin",
                                        })}
                                    />
                                </Drawer.Navigator>
                            </PaperProvider>
                        </PrivyWalletProvider>
                    </FundingMethodProvider>
                </ConnectionProvider>
            </PrivyProvider>
        </NavigationContainer>
    );
};
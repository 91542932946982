import React from "react";
import { View, StyleSheet, Text, Image } from "react-native";

const Icons = {
    inactive: require("../assets/orange_icons/Inactive.webp"),
    clock: require("../assets/orange_icons/Clock.webp"),
    active: require("../assets/orange_icons/stake_active.webp"),
};

export default function StakeAccountStatus({ state }) {
    if (state === "Inactive") {
        return (
            <View style={styles.statusContainer}>
                <Image source={Icons["inactive"]} style={[styles.inactiveImage]} />
                <Text style={[styles.stakeAccountText, styles.textRight]}>{state}</Text>
            </View>
        );
    } else if (state === "Activating" || state === "Deactivating") {
        return (
            <View style={styles.statusContainer}>
                <Image source={Icons["clock"]} style={styles.image} />
                <Text style={[styles.stakeAccountText, styles.textRight]}>{state}</Text>
            </View>
        );
    } else if (state === "Active") {
        return (
            <View style={styles.statusContainer}>
                <Image source={Icons["active"]} style={styles.image} />
                <Text style={[styles.stakeAccountText, styles.textRight]}>{state}</Text>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    statusContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
    },
    stakeAccountText: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
        overflow: 'hidden',
        textAlign: 'right',
        marginBottom: 2,
    },
    textRight: {
        textAlign: 'right'
    },
    image: {
        marginRight: 10,
        minHeight: 20,
        minWidth: 20,
        resizeMode: 'contain',
    },
    inactiveImage: {
        height: 17,
        width: 17,
        resizeMode: 'contain',
        marginRight: 10,
    }
});
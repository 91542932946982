import React, { useEffect } from "react";
import { Platform, StyleSheet, View, Text, TouchableOpacity, Image } from 'react-native';
import LinearGradient from "react-native-web-linear-gradient";
import { usePrivy, useSolanaWallets } from '@privy-io/react-auth';
import { usePrivyWalletContext } from '../components/providers/PrivyWalletProvider';
import LoadingSpinner from "../components/LoadingSpinner";
import { useNavigation } from '@react-navigation/native';

const LoginMethods = () => {
    const { login } = usePrivyWalletContext();
    const loading = false;
    const { user, ready } = usePrivy();
    const { wallets } = useSolanaWallets();
    const navigation = useNavigation();

    useEffect(() => {
        if (ready && user && wallets.length > 0) {
            navigation.navigate("Stake")
        }
    }, [ready, user, wallets, navigation]);

    return !loading ? (
        <View style={styles.container}>
            <View>
                <Text style={[styles.loginMethodText, styles.header]}>Let's get started!</Text>
            </View>
            <LinearGradient
                colors={['#4e4e4e4d', '#1d1d1d4d']}
                start={{ x: 0.5, y: 0 }}
                end={{ x: 0.5, y: 1 }}
                style={styles.gradient}
            >
                <TouchableOpacity
                    style={styles.loginMethod}
                    onPress={() => login("google")}
                >
                    <View style={styles.imageContainer}>
                        <Image source={require("../assets/orange_icons/Google.webp")} style={styles.methodIcon} />
                    </View>
                    <View style={styles.infoContainer}>
                        <Text style={styles.loginMethodText}>Sign in with Google</Text>
                    </View>
                </TouchableOpacity>
            </LinearGradient>
            <LinearGradient
                colors={['#4e4e4e4d', '#1d1d1d4d']}
                start={{ x: 0.5, y: 0 }}
                end={{ x: 0.5, y: 1 }}
                style={styles.gradient}
            >
                <TouchableOpacity
                    style={styles.loginMethod}
                    onPress={() => login("apple")}
                >
                    <View style={styles.imageContainer}>
                        <Image source={require("../assets/orange_icons/Apple.webp")} style={styles.methodIcon} />
                    </View>
                    <View style={styles.infoContainer}>
                        <Text style={styles.loginMethodText}>Sign in with Apple</Text>
                    </View>
                </TouchableOpacity>
            </LinearGradient>
            <LinearGradient
                colors={['#4e4e4e4d', '#1d1d1d4d']}
                start={{ x: 0.5, y: 0 }}
                end={{ x: 0.5, y: 1 }}
                style={styles.gradient}
            >
                <TouchableOpacity
                    style={styles.loginMethod}
                    onPress={() => navigation.navigate("EmailLogin")}
                >
                    <View style={styles.imageContainer}>
                        <Image source={require("../assets/orange_icons/Email.webp")} style={styles.methodIcon} />
                    </View>
                    <View style={styles.infoContainer}>
                        <Text style={styles.loginMethodText}>Sign in with Email</Text>
                    </View>
                </TouchableOpacity>
            </LinearGradient>
            <LinearGradient
                colors={['#4e4e4e4d', '#1d1d1d4d']}
                start={{ x: 0.5, y: 0 }}
                end={{ x: 0.5, y: 1 }}
                style={styles.gradient}
            >
                <TouchableOpacity
                    style={styles.loginMethod}
                    onPress={() => login("passkeySignup")}
                >
                    <View style={styles.imageContainer}>
                        <Image source={require("../assets/orange_icons/Key.webp")} style={styles.methodIcon} />
                    </View>
                    <View style={styles.infoContainer}>
                        <Text style={styles.loginMethodText}>Sign up with Passkey</Text>
                    </View>
                </TouchableOpacity>
            </LinearGradient>
            <LinearGradient
                colors={['#4e4e4e4d', '#1d1d1d4d']}
                start={{ x: 0.5, y: 0 }}
                end={{ x: 0.5, y: 1 }}
                style={styles.gradient}
            >
                <TouchableOpacity
                    style={styles.loginMethod}
                    onPress={() => login("passkeyLogin")}
                >
                    <View style={styles.imageContainer}>
                        <Image source={require("../assets/orange_icons/Key.webp")} style={styles.methodIcon} />
                    </View>
                    <View style={styles.infoContainer}>
                        <Text style={styles.loginMethodText}>Login with Passkey</Text>
                    </View>
                </TouchableOpacity>
            </LinearGradient>
            <LinearGradient
                colors={['#4e4e4e4d', '#1d1d1d4d']}
                start={{ x: 0.5, y: 0 }}
                end={{ x: 0.5, y: 1 }}
                style={styles.gradient}
            >
                <TouchableOpacity
                    style={styles.loginMethod}
                    onPress={() => login("externalWallet")}
                >
                    <View style={styles.imageContainer}>
                        <Image source={require("../assets/orange_icons/Wallet.webp")} style={styles.methodIcon} />
                    </View>
                    <View style={styles.infoContainer}>
                        <Text style={styles.loginMethodText}>Sign in with External Wallet</Text>
                        <Text style={styles.loginMethodSubtext}>(Requires a wallet like Phantom or Solflare installed)</Text>
                    </View>
                </TouchableOpacity>
            </LinearGradient>
        </View>
    ) : <LoadingSpinner />;
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'black',
        paddingTop: 50,
        height: '100%',
        alignItems: 'center',
    },
    loginMethodHeader: {
        textAlign: 'left',
        alignSelf: 'left',
        marginBottom: 10,
    },
    header: {
        marginBottom: 20,
    },
    gradient: {
        borderRadius: 25,
        marginBottom: 10,
        maxWidth: 700,
    },
    loginMethod: {
        borderWidth: 1,
        borderColor: '#4a4a4a',
        maxWidth: 700,
        borderRadius: 25,
        padding: 16,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        ...(Platform.OS === 'web' && { flexGrow: 1, flex: 1 }),
        alignItems: 'center',

    },
    loginMethodText: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
        overflow: 'hidden',
        textAlign: 'center',
        marginBottom: 2,
    },
    loginMethodSubtext: {
        color: '#FF8400',
        fontFamily: 'PP Neue Machina Plain',
        overflow: 'hidden',
        textAlign: 'center',
        marginBottom: 2,
        fontSize: 12,
    },
    imageContainer: {
        paddingRight: 30,
    },
    infoContainer: {
        paddingRight: 30,
        width: 300,
    },
    icon: {
        borderRadius: 50,
        justifyContent: 'center',
        alignItems: 'center',
        color: '#FF8400',
    },
    methodIcon: {
        height: 30,
        width: 30,
        resizeMode: 'contain',
    }
});

export default LoginMethods;
import React, { useState, useEffect } from 'react';
import { Platform, TextInput } from 'react-native';
import { Text, View, ScrollView, StyleSheet, TouchableOpacity } from "react-native";
import LinearGradient from "react-native-web-linear-gradient";
import { usePrivyWalletContext } from '../components/providers/PrivyWalletProvider';

const FundScreen = () => {
    const { selectedAccount, fundWallet } = usePrivyWalletContext();
    const [address, setAddress] = useState("");
    const [amount, setAmount] = useState(0);
    const handleAmount = (text) => {
        // Replace all characters except digits and a single decimal point
        const amount = text.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
        setAmount(amount);
    };

    useEffect(() => {
        if (selectedAccount) {
            setAddress(selectedAccount);
        }
    }, [selectedAccount]);

    return (
        <ScrollView
            style={styles.scrollViewContainer}
            contentContainerStyle={styles.scrollViewContainerContent}
        >
            <LinearGradient
                colors={['#4e4e4e4d', '#1d1d1d4d']}
                start={{ x: 0.5, y: 0 }}
                end={{ x: 0.5, y: 1 }}
                style={styles.container}
            >
                <Text style={[styles.headerText, styles.header]}>Amount</Text>
                <View style={styles.inputContainer}>
                    <TextInput
                        style={styles.input}
                        placeholder="0"
                        value={String(amount)}
                        onChangeText={handleAmount}
                        keyboardType="numeric"
                    />
                    <Text style={styles.inputSuffix}>SOL</Text>
                    <View>
                        <TouchableOpacity
                            style={[styles.buyButton, amount <= 0 || selectedAccount === null ? styles.disabledButton : null]}
                            disabled={amount <= 0 || selectedAccount === null}
                            onPress={() => fundWallet(address, {
                                amount: String(amount),
                                card: {
                                    preferredProvider: 'moonpay',
                                }
                            })}
                        >
                            <Text style={styles.buttonText}>BUY</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </LinearGradient>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    scrollViewContainer: {
        backgroundColor: 'black',
        flex: 1,
        ...(Platform.OS === "ios" && { paddingTop: 30 }),
    },
    scrollViewContainerContent: {
        alignItems: 'center',
        height: '100%',
        marginTop: 25,
        marginLeft: 25,
        marginRight: 25,
    },
    container: {
        width: 600,
        borderRadius: 16,
        padding: 24,
        minWidth: 300,
    },
    fundingProviderContainer: {
        marginTop: 15,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row'
    },
    disabledButton: {
        opacity: 0.5,
    },
    input: {
        backgroundColor: 'rgba(0,0,0,0.5)',
        borderWidth: 1,
        borderColor: '#717171',
        borderRadius: 25,
        fontFamily: 'PP Neue Machina Plain',
        color: 'white',
        fontSize: 18,
        paddingVertical: 12,
        paddingHorizontal: 16,
    },
    inputContainer: {
        position: 'relative',
        // marginBottom: 16,
        width: '100%',
    },
    inputSuffix: {
        position: 'absolute',
        right: 16,
        top: 17,
        color: '#717171',
        fontFamily: 'PP Neue Machina Plain',
    },
    headerText: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
        overflow: 'hidden',
        textAlign: 'left',
        marginBottom: 2,
    },
    header: {
        marginBottom: 15,
    },
    buyButton: {
        backgroundColor: '#d9d9d9',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '10%',
        alignItems: 'center',
        paddingVertical: 12,
        borderRadius: 25,
        width: '100%',
    },
    moonpay: {
        backgroundColor: '#7D00FF',
    },
    buttonText: {
        fontFamily: 'PP Neue Machina Plain',
        color: 'black',
    },
    fundingProviderText: {
        color: '#717171',
        textAlign: 'right',
        fontFamily: 'PP Neue Machina Plain',
    }
});

export default FundScreen;